import React, { useState } from "react"

import "./checkBox.css"

import Check from "assets/icons/check.png"

export default function CheckBox(props: {text: string, onCheck: Function}){

    const [checked, setChecked] = useState<boolean>(false)

    function handleCheck(){
        if (checked) {
            props.onCheck(false)
            setChecked(false)
        } else {
            setChecked(true)
            props.onCheck(true)
        }
    }

    return (
        <div id="checkbox-main">
            <div id="checkbox-selection-field">
                <div id="checkbox-selection-field-container" onClick={handleCheck}>
                    {
                        checked &&
                        (
                            <img 
                                id="checkbox-selection-field-img"
                                src={Check}
                                alt="" 
                                draggable={false}
                            />
                        )
                    }
                </div>
            </div>
            <div id="checkbox-text-container">
                <p id="checkbox-text">
                    {props.text}
                </p>
            </div>
        </div>
    )
}