import React, {useEffect, useState, useContext} from "react"

import "./steamLoginModal.css"

import CheckBox   from "../../checkBox/checkBox"
import CloseCross from "assets/icons/closeCross.png"
import Logo       from "./caseskins-logo.png"
import { APICaller } from "api/APICaller"
import { AuthContext } from "context/authProvider"


export default function SteamLoginModal(props: {onSteamLoginModalExitClick: Function}){

    const [legalAge,         setLegalAge        ] = useState<boolean>(false)
    const [agreementsAccept, setAgreementsAccept] = useState<boolean>(false)
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== process.env.REACT_APP_API_URL) return;

            const { token } = event.data;

            if (token) {
                authContext?.setToken(token);
                props.onSteamLoginModalExitClick(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        }
    }, []);

    function openChildWindow() {
        const url = process.env.REACT_APP_API_URL + '/steam/login';
        window.open(
            url,
            '_blank',
            'width=800,height=600'
        );
    }

    function handleExitBtnClick(){
        props.onSteamLoginModalExitClick(false)
    }

    function handleAgreementsAcceptChange(value: boolean){
        setAgreementsAccept(!agreementsAccept);
    }
    
    function handleLegalAgeChange(value: boolean){
        setLegalAge(!legalAge);
    }

    function handleLogin() {
        const isLoginValid = (agreementsAccept && legalAge);

        if (!isLoginValid) {

            if (!agreementsAccept)
                alert('Você deve estar de acordo com os Termos e Diretrizes e Políticas de Privacidade.');
            
            if (!legalAge)
                alert('Você deve possuir 18 anos ou mais para se registrar.');
        } else {
            openChildWindow();
            if (window) window.focus();
        }
    }

    return (
        <div id="user-steam-login-modal-main">
            <div id="user-steam-login-modal-content">
                <div id="user-steam-login-modal-exit-container">
                    <div id="user-steam-login-modal-exit">
                        <img 
                            id="user-steam-login-modal-exit-img"
                            src={CloseCross}
                            alt="" 
                            draggable={false}
                            onClick={handleExitBtnClick}
                        />
                    </div>
                </div>
                <div id="user-steam-login-modal-info-container">
                    <div id="user-steam-login-modal-info-logo">
                        <img id="user-steam-login-modal-info-logo-img" src={Logo} draggable={false}/>
                    </div>
                    <div id="user-steam-login-modal-info-text-container">
                        <p id="user-steam-login-modal-info-text">
                            Para continuar, confirme os dados a seguir para prosseguir com o login
                        </p>
                    </div>
                </div>
                <div id="user-steam-login-modal-checkbox">
                    <div className="user-steam-login-modal-checkbox-field">
                        <CheckBox 
                            text="Estou de acordo com os Termos e Diretrizes e Políticas de Privacidade"
                            onCheck={handleAgreementsAcceptChange}
                        />
                    </div>
                    <div className="user-steam-login-modal-checkbox-field">
                        <CheckBox
                            text="Sou maior de 18 anos"
                            onCheck={handleLegalAgeChange}
                        />
                    </div>
                </div>
                <div id="user-steam-login-modal-confirm">
                    <div id="user-steam-login-modal-confirm-btn" onClick={handleLogin}>
                        <p id="user-steam-login-modal-confirm-btn-text"
                        >
                            Iniciar sessão
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}