import React, { useState } from "react"

import Logo from "assets/icons/logoWithText.png"

import TextInputWithoutSave from "components/input/textInputWithoutSave/textInputWithoutSave"

import "./adminLogin.css"

export default function AdminLogin(){

    const [adminLogin, setAdminLogin] = useState<string>("")
    const [adminPassword, setAdminPassword] = useState<string>("")

    function handleLoginChange(value: string){
            
    }
    
    function handlePasswordChange(value: string){
        setAdminPassword(value)
    }
    
    function handleLoginBtnClick(){
    
    }

    return (
        <div id="admin-login-main">
            <div id="admin-login-content">
                <div id="admin-login-logo-container">
                    <img id="admin-login-logo" src={Logo} draggable={false}/>
                </div>
                <div id="admin-login-input-container">
                    <div className="admin-login-input">
                        <TextInputWithoutSave label="Login" onValueChange={handleLoginChange} onKeyDown={() => {}}/>
                    </div>
                    <div className="admin-login-input">
                        <TextInputWithoutSave label="Senha" onValueChange={handlePasswordChange} onKeyDown={() => {}} value={adminPassword} type="password"/>
                    </div>
                </div>
                <div id="admin-login-btn-container">
                    <div id="admin-login-btn" onClick={handleLoginBtnClick}>
                        <p id="admin-login-btn-text">
                            Entrar
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}