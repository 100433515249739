import React, { useEffect, useState } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ImageWithZoom, ButtonFirst, ButtonLast, DotGroup, Image } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./imageSlider.css"


import roupa from "./a.jpg"
import BANNER1 from "./img1.png"
import BANNER2 from "./a.png"
import IBanner from 'interfaces/IBanner';
import { APICaller, imagePrefix } from 'api/APICaller';
import { AxiosError, AxiosResponse } from 'axios';

interface IImage {
    id: number
    image: string;
}

export default function ImageSlider(){

    // const [productImgs, setProductImgs] = useState<IImage[]>([])
    const [banners, setBanners] = useState<IBanner[]>([]);
    const [currentSlide, setCurrentSlide] = useState<number>(0)

    useEffect(() => {
        APICaller
            .get('/banners')
            .then((response: AxiosResponse) => {
                const data: IBanner[] = response.data.data;
                setBanners(data);
            })
            .catch((error) => {
                alert(error.response.data.message)
            })
    }, [])

    function handleMenuImageClick(id: number){
        setCurrentSlide(id)
        console.log(id)
    }

    return (
        <CarouselProvider
            naturalSlideWidth={1200}
            naturalSlideHeight={350}
            totalSlides={banners.length}
            infinite={true}
            dragEnabled={false}
            className="teste12"
            currentSlide={currentSlide}
            isPlaying={true}
        >   
            <Slider className='slider'>
                {
                    banners.map((banner, index) => {
                        return (
                            <Slide className="img-container" index={index} key={banner.id}>
                                <Image src={imagePrefix('BANNERS', banner.filename)} hasMasterSpinner={false} draggable={false} style={{backgroundSize: 'cover'}} isBgImage={true}/>
                            </Slide>
                        )
                    })
                }
            </Slider>
            <DotGroup id='dot-group'/>
      </CarouselProvider>
    )
}