import React, {useContext, useEffect, useState} from "react"

import "./userConnected.css"

import Article  from "assets/userHeaderMenuDropdownIcons/article.png"
import Calendar from "assets/userHeaderMenuDropdownIcons/calendar.png"
import Case     from "assets/userHeaderMenuDropdownIcons/case.png"
import Exit     from "assets/userHeaderMenuDropdownIcons/exit.png"
import Help     from "assets/userHeaderMenuDropdownIcons/help.png"
import Settings from "assets/userHeaderMenuDropdownIcons/settings.png"
import Support  from "assets/userHeaderMenuDropdownIcons/support.png"
import Discord  from "assets/userHeaderMenuDropdownIcons/discord.png"

import Chevron  from "assets/icons/left-arrow.png"
import Ticket   from "assets/icons/silverTicket.png"
import Add      from "assets/icons/addGreen.png"

import G from "./Gesicht.png"
import { AuthContext } from "context/authProvider"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"
import { useNavigate } from "react-router-dom"

const menuEntries = [
    {
        id: 0,
        name: "Configurações",
        link: "/perfil",
        image: Settings
    },
    {
        id: 1,
        name: "Depositar caixas",
        link: "/inventario",
        image: Case
    },
    {
        id: 1,
        name: "Perguntas frequentes",
        link: "/faq",
        image: Help
    }
]

export default function UserConnected(props: { renderBuyTicketScreen: Function}){
    const [buttonHover, setButtonHover] = useState<boolean>(false)
    const [dropdownHover, setDropdownHover] = useState<boolean>(false)
    const [width, setWidth] = useState<number>(0)
    const [renderDropDown, setRenderDropDown] = useState<boolean>(false)
    const [position, setPosition] = useState<any>({positionX: 0, positionY: 0})
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [tokens, setTokens] = useState<number>();
    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        setRenderDropDown(false)
    }, [windowDimensions]);
    
    useEffect(() => {
        const elemento = document.getElementById('user-connected');
        if (elemento != null) {
            const rect = elemento.getBoundingClientRect();
            setWidth(rect.width)
            setPosition({
                X: rect.left,
                Y: rect.top + rect.height
            })
        }
        
    }, [window.innerWidth,window.innerHeight])

    useEffect(() => {
        APICaller
            .get('/wallets/tokens', {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                const data = response.data.data;
                setTokens(data)
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }, [])
    
    function handleRenderDropdown(){
        if (renderDropDown == true){
            setRenderDropDown(false)
        } else {
            setRenderDropDown(true)
        }
    }

    function backgroundColorController(){
        if (renderDropDown === true){
            return {
                borderBottomLeftRadius: "0px", 
                borderBottomRightRadius: "0px",
                borderRight: "3px #162D46 solid",
                borderLeft: "3px #162D46 solid",
                borderTop: "3px #162D46 solid"
            }
        } else {
            return {
                borderBottomLeftRadius: "10px", 
                borderBottomRightRadius: "10px",
            }
        }
    }

    function handleBuyTicketBtnClick() {
        if (props.renderBuyTicketScreen !== undefined){
            props.renderBuyTicketScreen(true)
        }
    }

    function handleLogoff() {
        authContext?.rmToken();
        navigate("/");
    }

    return (
        <div id="user-connected-main">
            <div id="user-connected-ticket">
                <div id="user-connected-ticket-img-container">
                    <img
                        id="user-connected-ticket-img" 
                        src={Ticket} 
                        alt="" 
                        draggable={false}
                    />
                </div>
                <div id="user-connected-ticket-qnt">
                    <p id="user-connected-ticket-qnt-txt">
                        { tokens }
                    </p>
                </div>
                <div id="user-connect-ticket-add-img-container">
                    <img 
                        id="user-connect-ticket-add-img"
                        src={Add}
                        alt="" 
                        draggable={false}
                        onClick={handleBuyTicketBtnClick}
                    />
                </div>
            </div>

            <div id="user-connected" onClick={handleRenderDropdown} style={backgroundColorController()}>
                <div id="user-connected-img-container">
                    <img 
                        id="user-connected-img" 
                        src={ authContext?.avatar } 
                        alt=""
                        draggable={false}
                    />
                </div>
                <div id="user-connected-name">
                    <p id="user-connected-name-txt">
                        { authContext?.username }
                    </p>
                </div>
                <div id="user-connected-chevron">
                    <div id="user-connected-chevron-box">
                        <img 
                            id="user-connected-chevron-img" 
                            className={renderDropDown? "user-connected-chevron-rotate": "user-connected-chevron-rotate-inverse"}
                            src={Chevron} 
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <ul id="user-connected-menu" 
                style={{display: renderDropDown? "flex": "none", top: position.Y, left: position.X, width: width}}
            >
                {
                    menuEntries.map(entry => {
                        return (
                            <li className="user-connected-menu-entry" key={entry.id}>
                                <a className="user-connected-menu-entry-link" href={entry.link}>
                                    <div className="user-connected-menu-entry-link-img-container">
                                        <img className="user-connected-menu-entry-link-img" src={entry.image} alt="" />
                                    </div>
                                    <p className="user-connected-menu-entry-link-txt">
                                        {entry.name}
                                    </p>
                                </a>
                            </li>
                        )
                    })
                }
                <li className="user-connected-menu-entry" id="user-connected-menu-entry-exit" key={7} onClick={handleLogoff}>
                    <div className="user-connected-menu-entry-link-img-container">
                        <img className="user-connected-menu-entry-link-img" src={Exit} alt="" />
                    </div>
                    <p className="user-connected-menu-entry-link-txt">
                        Sair
                    </p>
                </li>
            </ul>
        </div>
    )
}