import React, { useState, useEffect, useContext } from "react"

import "./profile.css"

import Add      from "assets/icons/addGreen.png"
import Ticket   from "assets/icons/silverTicket.png"
import Header                from "components/header/header"
import Footer                from "components/footer/footer"
import TextInputWithoutLabel from "components/input/textInputWithoutLabel/textInputWithoutLabel"
import { AuthContext } from "context/authProvider"
import IUser from "interfaces/IUser"
import INormalUser from "interfaces/INormalUser"
import { APICaller } from "api/APICaller"
import { AxiosResponse } from "axios"
import IEmail from "interfaces/IEmail"

export default function UserProfile() {

    const [discord, setDiscord] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>();
    const [tradeUrl, setTradeUrl] = useState<string>();
    const [tickets, setTickets] = useState<number>(0); 

    const authContext = useContext(AuthContext);

    useEffect(() => {
        setDiscord(authContext?.discord ?? 'N/A');
        setTradeUrl(authContext?.trade_url ?? 'N/A');

        APICaller
            .get('/emails/',
                {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`
                    }
                }
            )
            .then((response: AxiosResponse) => {
                const data: IEmail = response.data.data;
                setEmail(data.address);
                setIsEmailConfirmed(data.is_email_confirmed);
            })
            .catch((error) => {
                setEmail('N/A');
                setIsEmailConfirmed(false);
            });

            APICaller
            .get('/wallets/tokens', {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                const data = response.data.data;
                setTickets(tickets)
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }, []);
    
    function handleTradeUrlChange(value: string){
        setTradeUrl(value);
    }

    function handleEmailChange(value: string){
        setEmail(value);
    }

    function handleDiscordChange(value: string){
        setDiscord(value)
    }


    function handleTradeUrlSave(value: boolean){

    }

    function handleEmailSave(value: boolean){

    }

    function handleDiscordSave(value: boolean){

    }

    return (
        <>  
            <Header/>
            <div id="user-account-main">
                <div id="user-account-content">
                    <div id="user-account-content-title">
                        <p id="user-account-content-title-text">
                            Minha Conta
                        </p>
                    </div>
                    <div id="user-account-content-container">
                        <div id="user-account-primary-info" className="user-account-info">
                            <div className="user-account-primary-info-input">
                                <p className="user-account-primary-info-input-label">
                                    URL de troca
                                </p>
                                <div className="user-account-info-decoration"/>
                                <p className="user-account-primary-info-input-instruction">
                                    PARA ENCONTRAR SUA URL DE TROCA <a id="user-account-primary-info-trade-url-link" href="">CLIQUE AQUI</a>
                                </p>
                                <div className="user-account-primary-info-input-field-container">
                                    <TextInputWithoutLabel onValueChange={handleTradeUrlChange} onSaveBtnClick={handleTradeUrlSave} value={tradeUrl}/>
                                </div>
                            </div>
                            <div className="user-account-primary-info-input-type2">
                                <p className="user-account-primary-info-input-label">
                                    E-mail
                                </p>
                                <div className="user-account-info-decoration"/>
                                <div className="user-account-primary-info-input-instruction-verify">
                                    <p className="user-account-primary-info-input-instruction">
                                        ADICIONE UM E-MAIL PARA VERIFICAÇÃO DE CONTA
                                    </p>
                                    <div className="user-account-primary-info-input-verify">

                                    </div>
                                </div>
                                <div className="user-account-primary-info-input-field-container">
                                    <TextInputWithoutLabel onValueChange={handleTradeUrlChange} onSaveBtnClick={handleTradeUrlSave} value={email}/>
                                </div>
                                <p className="user-account-primary-info-input-instruction2">
                                    REENVIAR E-MAIL DE CONFIRMAÇÃO
                                </p>
                            </div>
                            <div className="user-account-primary-info-input">
                                <p className="user-account-primary-info-input-label">
                                    Discord
                                </p>
                                <div className="user-account-info-decoration"/>
                                <div className="user-account-primary-info-input-instruction-verify">
                                    <p className="user-account-primary-info-input-instruction">
                                        ADICIONE O SEU ID DO DISCORD
                                    </p>
                                    <div className="user-account-primary-info-input-verify">

                                    </div>
                                </div>
                                <div className="user-account-primary-info-input-field-container">
                                    <TextInputWithoutLabel onValueChange={handleTradeUrlChange} onSaveBtnClick={handleTradeUrlSave} value={discord}/>
                                </div>
                            </div>
                            <ul id="user-account-primary-info-input-history-content">
                                
                            </ul>
                        </div>
                        <div id="user-account-secondary-info" className="user-account-info">
                            <div id="user-account-secondary-info-tickets">
                                <p className="user-account-secondary-info-label">
                                    Meus tickets na CaseSkins
                                </p>
                                <div className="user-account-info-decoration"/>
                                <p id="user-account-secondary-info-tickets-info">
                                    PARA ADICIONAR MAIS TICKETS À SUA CONTA, CLIQUE NO BOTÃO +
                                </p>
                                <div id="user-account-secondary-info-tickets-add-btn">
                                    <div id="user-account-secondary-info-ticket-img-container">
                                        <img
                                            id="user-account-secondary-info-ticket-img" 
                                            src={Ticket} 
                                            alt="" 
                                            draggable={false}
                                        />
                                    </div>
                                    <div id="user-account-secondary-info-ticket-qnt">
                                        <p id="user-account-secondary-info-ticket-qnt-txt">
                                            { tickets }
                                        </p>
                                    </div>
                                    <div id="user-account-secondary-info-ticket-add-img-container">
                                        <img 
                                            id="user-account-secondary-info-ticket-add-img"
                                            src={Add}
                                            alt="" 
                                            draggable={false}
                                            onClick={() => {}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="user-account-secondary-info-plan">
                                {/* <p className="user-account-secondary-info-label">
                                    Meu Plano
                                </p>
                                <div className="user-account-info-decoration"/>
                                <div id="user-account-secondary-info-plan-infos-container">
                                    <div className="user-account-secondary-info-plan-infos">
                                        <p className="user-account-secondary-info-plan-infos-label">
                                            Descrição:
                                        </p>
                                        <p className="user-account-secondary-info-plan-infos-value">
                                            Sorteio VIP
                                        </p>
                                    </div>
                                    <div className="user-account-secondary-info-plan-infos">
                                        <p className="user-account-secondary-info-plan-infos-label">
                                            Valor do prêmio:
                                        </p>
                                        <p className="user-account-secondary-info-plan-infos-value">
                                            R$ 13.000
                                        </p>
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}