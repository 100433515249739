import React from "react"

import "./levelListElement.css"

import trashCan from "assets/icons/trashCan.png" 

export default function LevelListElement(props: {id: string,
     position: number,
     hasDeleteButton: boolean,
     onDeleteLevel: Function}){

    function handleDeleteBtnClick() {
        props.onDeleteLevel(props);
    }

    return (
        <li id="level-list-element-main" key={props.position}>
            <div id="level-list-element-label">
                <p id="level-list-element-label-txt">
                    NÍVEL
                </p>
                <p id="level-list-element-label-number-txt">
                    {props.position}
                </p>
            </div>
            {
                props.hasDeleteButton &&
                <div id="level-list-element-btn-container">
                <div id="level-list-element-btn" onClick={handleDeleteBtnClick}>
                    <p id="level-list-element-btn-txt">
                        Excluir
                    </p>
                    <img id="level-list-element-btn-img" src={trashCan}/>
                </div>
            </div>
            }
        </li>
    )
}