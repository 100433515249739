import React, { useContext, useEffect, useState } from "react"

import "./partnerList.css"  
import add from "assets/icons/add.png"

import PartnerCard from "./partnerCard/partnerCard"
import SearchField from "components/searchField/searchField"
import { IPartner, PartnerStatus } from "interfaces/IPartner"
import { APICaller } from "api/APICaller"
import { AxiosResponse } from "axios"

export default function PartnerList(props: { partners: IPartner[], renderPartnerDetail: Function}) {

    const [searchField, setSearchField] = useState<string>("");
    const [filteredPartnerList, setFilteredPartnerList] = useState<IPartner[]>([]);
    
    const [partner, setPartner] = useState<IPartner>({
        id: '',
        name: 'N/A',
        profession: 'N/A',
        description: 'N/A',
        external_url: 'N/A',
        coupon: 'N/A',
        partner_image: 'N/A',
        expiration_date_start: '1/1/1999',
        expiration_date_end: '1/1/1999',
        status: PartnerStatus.ACTIVE
    });

    useEffect(() => {

    }, [props.partners])
    
    useEffect(() => {
        setFilteredPartnerList(
            props.partners.filter((partner) => partner.name.toLowerCase().includes(searchField.toLowerCase()))
        );
    }, [searchField]);

    function handlePartnerDetailClick(partner: IPartner){
        props.renderPartnerDetail(partner);
    }

    function handleAddPartnerClick() {  
        props.renderPartnerDetail(partner)
    }

    function handleSearchFieldChange(value: string){
        setSearchField(value);
    }


    return (
        <div id="partner-list-main">
            <div id="partner-list-section-title-container">
                <p id="partner-list-section-title-text">
                    Parceiros
                </p>
            </div>
            <div id="partner-list-decoration"/>
            <div id="partner-list-content">
                    <div id="partner-list-search-partner">
                        <div id="partner-list-search-partner-container">
                            <SearchField placeholder="Insira o nome do parceiro" onValueChange={handleSearchFieldChange}/>
                        </div>
                    </div>
                    <div id="partner-list-add-partner">
                            <div id="partner-list-add-partner-btn" onClick={handleAddPartnerClick}>
                                <p id="partner-list-add-partner-btn-txt">
                                    ADICIONAR PARCEIRO
                                </p>
                                <img id="partner-list-add-partner-btn-img" src={add} alt="" />
                            </div>
                        </div>
                    <ul id="partner-list-partners">
                    {
                        filteredPartnerList.map(partner => {
                            return (
                                <PartnerCard
                                    key={partner.id}
                                    onPartnerDetailClick={handlePartnerDetailClick}
                                    partner={partner}
                                />
                            )
                        })
                    }
                    </ul>
            </div>
        </div>
    )
}