import React, { useContext, useEffect, useState } from "react"

import "./userList.css"

import IUser from "interfaces/IUser"

import UserCard from "./userCard/userCard"
import SearchField from "components/searchField/searchField"
import { APICaller } from "api/APICaller"
import { Axios, AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"
import INormalUser from "interfaces/INormalUser"
import NormalUserCard from "./normalUserCard/normalUserCard"

export default function UserList(props: {renderUserDetail: Function, renderNormalUserDetail: Function }){

    const [steamUsers, setSteamUsers] = useState<IUser[]>([])
    const [users, setUsers] = useState<INormalUser[]>([]);
    
    const [filteredSteamUsers, setFilteredSteamUsers] = useState<IUser[]>([])
    const [filteredUsers, setFilteredUsers] = useState<INormalUser[]>([]);

    const [searchField, setSearchField] = useState<string>('');
    
    const authContext = useContext(AuthContext)

    useEffect(() => {

        APICaller
            .get('/steam/', {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IUser[] = response.data.data;
                setSteamUsers(data);
                setFilteredSteamUsers(data)
            })
            .catch((error) => {
                alert(error.response.data.message)
            })  
        
        APICaller
            .get('/auth/', {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                const data = response.data.data;
                setUsers(data);
                setFilteredUsers(data);
            }).catch((error) => {
                alert(error.response.data.message)
            })
        
    }, [])

    useEffect(() => {
        setFilteredSteamUsers(
            steamUsers.filter((su) => su.persona_name.includes(searchField))
        )

        setFilteredUsers(
            users.filter((u) => u.username.includes(searchField))
        )
    }, [searchField])

    function handleSteamUserInfoClick(id: number){
        for (let index = 0; index < steamUsers.length; index++) {
            if (steamUsers[index].id == id){
                props.renderUserDetail(steamUsers[index])
            }
            
        }
    }

    function handleUserInfoClick(id: number) {
        for (let index = 0; index < users.length; index++) {
            if (users[index].id == id){
                props.renderNormalUserDetail(users[index])
            }
            
        }
    }

    function handleSearchFieldChange(value: string){
        setSearchField(value);
    }

    return (
        <div id="user-list-main">
            <div id="user-list-section-title-container">
                <p id="user-list-section-title-text">
                    Usuários
                </p>
            </div>
            <div id="user-list-decoration"/>
            <div id="user-list-content">
                <div id="user-list-search-user">
                    <div id="user-list-search-user-container">
                        <SearchField placeholder="Insira o nome" onValueChange={handleSearchFieldChange}/>
                    </div>
                </div>
                <div id="user-list-all-steamUsers">
                    <div id="user-list-labels">
                        <div id="user-list-label-name">
                            <p className="user-list-text">
                                Nome
                            </p>
                        </div>
                        <div id="user-list-label-email">
                            <p className="user-list-text">
                                Discord
                            </p>
                        </div>
                        <div id="user-list-steam-link">
                            <p className="user-list-text">
                                Perfil
                            </p>
                        </div>
                    </div>
                    <ul id="userlist-steamUsers">
                        {
                            filteredSteamUsers.map(user => {
                                return (
                                    <UserCard
                                        key={user.id}
                                        onMoreInfoClick={handleSteamUserInfoClick}
                                        user={user}
                                    />
                                )
                            })
                        }
                        {
                            filteredUsers.map(user => {
                                return (
                                    <NormalUserCard
                                        key={user.id}
                                        onMoreInfoClick={handleUserInfoClick}
                                        user={user}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
