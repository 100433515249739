import React, { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"

import "./bannerConfiguration.css"

import BannerListElement from "./components/bannerListElement/bannerListElement"
import IBanner from "interfaces/IBanner"

import add from "assets/icons/add.png"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"

export default function BannerConfiguration(props: {renderBannerDetail: Function}){

    const [banners, setBanners] = useState<IBanner[]>([])
    const [update, setUpdate] = useState<boolean>(false)
    const authContext = useContext(AuthContext)

    useEffect(() => {
        APICaller
            .get('/banners/')
            .then((response: AxiosResponse) => {
                const data: IBanner[] = response.data.data;
                setBanners(data)
            })
            .catch((error) => {
                alert(error.response.data.message)
            })
    }, [])

    function handleEditBanner(banner: IBanner){
        props.renderBannerDetail(banner)
    }

    function handleCreatebanner(){
        setUpdate(!update)
        APICaller
            .post('/banners/create', null, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IBanner = response.data.data;
                setBanners([...banners, data]);
            })
            .catch((error) => {
                alert(error.response.data.message)
            })
    }

    return (
        <div id="banner-configuration-main">
            <div id="banner-configuration-section-title-container">
                <p id="banner-configuration-section-title-text">
                    Banners
                </p>
            </div>
            <div id="banner-configuration-decoration"/>
            <div id="banner-configuration-content">
                    <div id="banner-configuration-new-banner">
                        <div id="banner-configuration-new-banner-btn" onClick={handleCreatebanner}>
                            <p id="banner-configuration-new-banner-btn-txt">
                                ADICIONAR BANNER
                            </p>
                            <img id="banner-configuration-new-banner-btn-img" src={add} alt="" />
                        </div>
                    </div>
                    <ul id="banner-configuration-banner-list">
                        {
                            banners.map((banner, index) => {
                                return (
                                    <BannerListElement
                                        key={banner.id}
                                        banner={banner}
                                        position={index + 1}
                                        onEditBanner={handleEditBanner}
                                    />
                                )
                            })
                        }
                    </ul>
            </div>
        </div>
    )
}