import React, { useContext } from "react"
import { useState, useEffect } from "react"

import "./level.css"
import Logo from "assets/logo/logo-round.png"

import SilverTicket from "assets/icons/silverTicket.png"
import CaseIcon from "./case_icon.png"
import Group from "assets/icons/group.png" 

import TextInput from "components/input/textInput/textInput"
import LevelCard from "components/levelCard/levelCard"
import WinnerSection from "components/winnerSection/winnerSection"
import UploadFileBtn from "components/uploadFileBtn/uploadFileBtn"

import { ILevel } from "interfaces/ILevel"
import { APICaller, imagePrefix } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"
import { IRaffle, IRaffleStatus } from "interfaces/IRaffle"
import { AuthContext } from "context/authProvider"
import IRaffleStats from "interfaces/IRaffleStats"

export default function Level(props: {level: ILevel | undefined}){
    
    const [participantAmount, setParticipantAmount] = useState<number>(0);
    const [tickets, setTickets] = useState<number>(0);
    const [name, setName] = useState<string>('N/A');
    const [itemValue, setItemValue] = useState<number>(0);
    const [float, setFloat] = useState<number>(0);
    const [svc, setSvc] = useState('N/A');
    const [percentage, setPercentage] = useState<number>(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [updatedImage, setUpdatedImage] = useState<boolean>(false);

    const [imageForm, setImageForm] = useState<any>();
    const [image, setImage] = useState<any>();

    const [raffle, setRaffle] = useState<IRaffle>(
        {
            id: '',
            status: IRaffleStatus.COMING,
            item_name: 'N/A',
            item_value: 0,
            item_float: 0,
            item_svc: 'N/A',
            item_percentage: 0,
            item_image: '',
            boxes_earned: 0,
            level_id: props.level?.id || "",
            winner: ''
        }
    );

    const authContext = useContext(AuthContext);

    useEffect(() => {
        APICaller
            .get(`/raffles/${props.level?.id}`, 
                {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`
                    }
                }
            )
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);

                if (data.item_image !== '') {
                    setImage(imagePrefix('RAFFLES', data.item_image));
                } else {
                    setImage(Logo);
                }

                APICaller
                .get(`/raffles/${data.id}/stats`,
                    {
                        headers: {
                            Authorization: `Bearer ${authContext?.getToken()}`
                        }
                    }
                )
                .then((response: AxiosResponse) => {
                    const data: IRaffleStats = response.data.data;
                    setParticipantAmount(data.participant_count);
                    console.log(data);
                    setTickets(data.tickets);
                }).catch((error) => {
                    alert(error.response.data.message)
                })
                
            }).catch((error) => {
                setImage(Logo);
            })

       
    }, [props.level]);

    useEffect(() => {
        if (isUpdating) {
            stageChanges();
            setIsUpdating(false);

            alert('Sorteio atualizado com sucesso.');
        }
    }, [isUpdating]);

  
    function parseStatusColor(status: IRaffleStatus | undefined){
        if (status === IRaffleStatus.COMING){
            return "#CBCBCB"
        } 
        if (status === IRaffleStatus.ONGOING){
            return "#FF784E"
        }
        if (status === IRaffleStatus.COMPLETE){
            return "#5CB85C"
        } else {
            return "FFFFFF"
        }
    }

    function parseStatus(status: IRaffleStatus | undefined){
        if (status === IRaffleStatus.COMING) {
            return "EM BREVE"
        } 
        if (status === IRaffleStatus.ONGOING) {
            return "EM CAPTAÇÃO"
        }
        if (status === IRaffleStatus.COMPLETE) {
            return "CAPTAÇÃO COMPLETA"
        } else {
            return ""
        }
    }

    function handleItemNameChange(value: string){
        setName(value);   
    }

    function handleItemValueChange(value: number){
        setItemValue(value)
    }

    function handleItemFloatChange(value: number){
        setFloat(value)
    }

    function handleItemSteamCodeChange(value: string){
        setSvc(value)
    }

    function handleItemPercentChange(value: number){
        setPercentage(value)
    }

    function handleSave() {
        setRaffle(
            prevRaffle => ({
                ...prevRaffle,
                item_name: name,
                item_value: itemValue,
                item_float: float,
                item_svc: svc,
                item_percentage: (percentage >= 0 && percentage <= 100) ? percentage : 0,
            })
        )
        
        setIsUpdating(true);
    }

    function updateImage(raffleId: string) {
        APICaller
            .put(`/raffles/image/${raffleId}`, imageForm, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`,
                    "Content-Type": 'multipart/form-data'
                }
            })
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);
                setImage(imagePrefix('RAFFLES', data.item_image));
                setUpdatedImage(false);
            }).catch((error) => {
                alert(error.response.data.message)
            })  
    }

    function startRaffle() {
        APICaller
            .put(`/raffles/start/${props.level?.id}`, null, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }

    function endRaffle() {
        APICaller
            .put(`/raffles/end/${props.level?.id}`, null, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }

    function chooseWinner() {
        APICaller
            .put(`/raffles/complete/${props.level?.id}`, null, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }

    function handleUploadImg(img: any){
        const form = new FormData();
        form.append("image", img);
        setImage(URL.createObjectURL(img))
        setImageForm(form)
        setUpdatedImage(true)
    }

    function createRaffle() {
        APICaller
            .post('/raffles/create', raffle, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);

                if (updatedImage) {
                    updateImage(data.id);
                }
                
            })
            .catch((error) => {
                alert(error.response.data.message)
            });
    }

    function updateRaffle() {
        APICaller
            .put(`/raffles/update/${raffle.id}`, raffle, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IRaffle = response.data.data;
                setRaffle(data);

                if (updatedImage) {
                    updateImage(data.id);
                }
            })
            .catch((error) => {
                alert(error.response.data.message)
            })
        }

    function stageChanges() {
        if (raffle.id === '') {
            createRaffle();
        } else {    
           updateRaffle();
        }
    }

    return (
        <div id="level-main">
            <div id="control-panel-content-level">
                <p id="control-panel-content-level-text">NÍVEL</p>
                <p id="control-panel-content-level-text-number">{props.level?.position}</p>
            </div>
            <div id="control-panel-content-decoration"/>
            <div id="control-panel-content-item-info">
                <div id="control-panel-content-item-info-container">
                    <div id="control-panel-content-item-info-primary">
                        <div id="control-panel-content-item-info-1">
                            <div className="control-panel-content-item-info-1-input">
                                <TextInput 
                                    label="Nome do item" 
                                    value={raffle?.item_name} 
                                    onValueChange={handleItemNameChange} 
                                    onSaveBtnClick={handleSave}
                                />
                            </div>
                            <div className="control-panel-content-item-info-1-input">
                                <TextInput 
                                    label="Valor do item" 
                                    value={raffle?.item_value}
                                    onValueChange={handleItemValueChange} 
                                    onSaveBtnClick={handleSave}
                                />
                            </div>
                            <div className="control-panel-content-item-info-1-input">
                                <TextInput 
                                    label="Float" 
                                    value={raffle?.item_float}
                                    onValueChange={handleItemFloatChange} 
                                    onSaveBtnClick={handleSave}
                                />
                            </div>
                            <div className="control-panel-content-item-info-1-input">
                                <TextInput 
                                    label="Código de visualização Steam" 
                                    value={raffle?.item_svc}
                                    onValueChange={handleItemSteamCodeChange} 
                                    onSaveBtnClick={handleSave}
                                />
                            </div>
                            <div className="control-panel-content-item-info-1-input">
                                <TextInput 
                                    label="Porcentagem" 
                                    value={raffle?.item_percentage}
                                    onValueChange={handleItemPercentChange} 
                                    onSaveBtnClick={handleSave}
                                />
                            </div>
                        </div>
                        <div id="control-panel-content-item-info-2">
                            <div id="control-panel-content-item-captation-and-image">
                                <div id="control-panel-content-item-image">
                                    <img 
                                        id="control-panel-content-item-image-view"
                                        src={image}
                                        alt="Imagem do nível"
                                    />
                                    <div id="control-panel-content-item-image-upload" onClick={() => {}}>
                                        <UploadFileBtn parent="level"  onUploadImg={handleUploadImg}/>
                                    </div>
                                </div>
                                <div id="control-panel-content-item-captation">
                                    <div id="control-panel-item-captation-title">
                                        <p id="control-panel-item-captation-title-text">
                                            Captação
                                        </p>
                                    </div>
                                    <div id="control-panel-item-captation-content">
                                        <div className="control-panel-content-item" id="control-panel-content-item-captation-users">
                                            <div className="control-panel-content-item-img">
                                                <img id="enroled-users-icon" src={Group} alt="Ícone de grupo"></img>
                                            </div>
                                            <div className="control-panel-content-item-value-container">
                                                <p className="control-panel-content-item-value">
                                                    {participantAmount}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="control-panel-content-item" id="control-panel-content-item-captation-box">
                                            <div className="control-panel-content-item-img">
                                                <img id="case-icon" src={CaseIcon} draggable={false} alt="Ícone de caixa"/>
                                            </div>
                                            <div className="control-panel-content-item-value-container">
                                                <p className="control-panel-content-item-value">{ raffle?.boxes_earned }</p>
                                            </div>
                                        </div>
                                        <div className="control-panel-content-item">
                                            <div className="control-panel-content-item-img">
                                                <img id="golden-ticket-icon" src={SilverTicket} draggable={false} alt="Ícone de ticket prateado"/>
                                            </div>
                                            <div className="control-panel-content-item-value-container">
                                                <p className="control-panel-content-item-value">{ tickets }</p>
                                            </div>
                                        </div>
                                        <div className="control-panel-content-item">
                                            <div className="control-panel-content-item-img">
                                                <p className="control-panel-content-item-value-money">
                                                    R$
                                                </p>
                                            </div>
                                            <div className="control-panel-content-item-value-container">
                                                <p className="control-panel-content-item-value control-panel-content-item-value-money" >{ (tickets * 2.5).toFixed(2) }</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div id="control-panel-content-item-status-container">
                                <div id="control-panel-content-item-status">
                                    <div id="control-panel-content-item-status-label">
                                        <p id="control-panel-content-item-status-label-text">
                                            Status
                                        </p>
                                    </div>
                                    <div id="control-panel-content-item-status-advice" style={{backgroundColor: parseStatusColor(raffle?.status)}}>
                                        <p id="control-panel-content-item-status-advice-text">
                                            {
                                                parseStatus(raffle?.status)
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="control-panel-content-item-info-secondary">
                        {
                            raffle && <LevelCard raffle={raffle}/>
                        }
                        <div id="content-raffle">
                            <div id="content-raffle-label">
                                <p id="content-raffle-label-txt">
                                    Sorteador
                                </p>
                            </div>
                            <div id="content-raffle-info">
                                <p id="content-raffle-info-txt">
                                    EXISTEM
                                </p>
                                <p id="content-raffle-info-txt-value">
                                    {participantAmount}
                                </p>
                                <p id="content-raffle-info-txt">
                                    JOGADORES NESTE SORTEIO
                                </p>
                            </div>
                            <div id="content-raffle-start">
                                {
                                    raffle.status === IRaffleStatus.ONGOING && 
                                    <div id="content-raffle-start-btn" onClick={chooseWinner}>
                                        <p id="content-raffle-start-btn-txt">
                                            SORTEAR VENCEDOR
                                        </p>
                                    </div>
                                }
                            </div>
                            <div id="control-panel-content-item-info-add-item-container">
                                {
                                    raffle.status == IRaffleStatus.COMING && 
                                    <div id="control-panel-content-item-info-add-item-btn-start" onClick={startRaffle}>
                                        <p id="control-panel-content-item-info-add-item-btn-txt">
                                            INICIAR PROCESSO
                                        </p>
                                    </div>  
                                }
                                {
                                    raffle.status == IRaffleStatus.COMPLETE && 
                                    <div id="control-panel-content-item-info-add-item-btn-end" onClick={endRaffle}>
                                        <p id="control-panel-content-item-info-add-item-btn-txt">
                                            FINALIZAR PROCESSO
                                        </p>
                                    </div>  
                                }
                                
                            </div>
                        </div>
                    </div>
                    {/* <div id="control-panel-content-item-info-winner">
                        <WinnerSection/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}