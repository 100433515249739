import React from "react"

import "./footer.css"

import Discord   from "assets/socialNetworkIcons/discord.png"
import Instagram from "assets/socialNetworkIcons/instagram.png"
import Tiktok    from "assets/socialNetworkIcons/tiktok.png"
import Twitch    from "assets/socialNetworkIcons/twitch.png"
import X         from "assets/socialNetworkIcons/x.png"
import Youtube   from "assets/socialNetworkIcons/youtube.png"

import DiscordHotLink from "assets/hotLinks/discord.png"
import PartnersHotLink from "assets/hotLinks/parners.png"
import SupportHotLink from "assets/hotLinks/support.png"
import TermsHotLink from "assets/hotLinks/terms.png" 

export default function Footer() {
    return (
        <div id="footer-main">
            <div id="footer-hot-links">
                <a className="footer-hot-link" href="/parceiros" title="Parceiros">
                    <img 
                            className="footer-hot-link-img"
                            src={PartnersHotLink} 
                            alt="Parceiros" 
                    />
                </a>
                <a className="footer-hot-link" href="/termos-diretrizes" title="Termos e Diretrizes">
                    <img 
                            className="footer-hot-link-img"
                            src={TermsHotLink} 
                            alt="Termos e Diretrizes" 
                        />
                </a>
                <a className="footer-hot-link" href="/discord" title="Discord">
                    <img 
                            className="footer-hot-link-img"
                            src={DiscordHotLink} 
                            alt="Discord" 
                        />
                </a>
            </div>
            {/* <div id="footer-social-media">
                <a className="footer-social-media-link" href="">
                    <img className="footer-social-media-link-img" src={Twitch} alt="" />
                </a>
                <a className="footer-social-media-link" href="">
                    <img className="footer-social-media-link-img" src={Discord} alt="" />
                </a>
                <a className="footer-social-media-link" href="">
                    <img className="footer-social-media-link-img" src={Youtube} alt="" />
                </a>
                <a className="footer-social-media-link" href="">
                    <img className="footer-social-media-link-img" src={Instagram} alt="" />
                </a>
                <a className="footer-social-media-link" href="">
                    <img className="footer-social-media-link-img" src={X} alt="" />
                </a>
                <a className="footer-social-media-link" href="">
                    <img className="footer-social-media-link-img" src={Tiktok} alt="" />
                </a>
            </div> */}
            <div id="footer-legal-info">
                <p id="footer-legal-info-txt">
                    caseskins.gg LTDA - Não somos afiliados à Valve Corp. - suporte@caseskins.gg
                </p>
            </div>
        </div>
    )
}