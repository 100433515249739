import React, { useContext, useEffect, useState } from "react"

import "./partnerProfile.css"

import TextInput      from "components/input/textInput/textInput"
import TextInputLarge from "components/input/textInputLarge/textInputLarge"
import UploadFileBtn  from "components/uploadFileBtn/uploadFileBtn"
import { IPartner, PartnerStatus } from "interfaces/IPartner"
import { APICaller, imagePrefix } from "api/APICaller"
import { AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"
import IDate, { IDateToString, StringToIDate } from "interfaces/IDate"

export default function PartnerProfile(props: {partner: IPartner, setPartner: Function, onDelete: Function}){

    const [name, setName] = useState<string>();
    const [profession, setProfession] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [externalUrl, setExternalUrl] = useState<string>();
    const [coupon, setCoupon] = useState<string>();
    const [expirationDateStart, setExpirationDateStart] = useState<string>();
    const [expirationDateEnd, setExpirationDateEnd] = useState<string>();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [updatedImage, setUpdatedImage] = useState<boolean>(false);
    const [imageForm, setImageForm] = useState<any>();
    const [image, setImage] = useState<any>()
    const [imageName, setImageName] = useState<string>("");
    
    const authContext = useContext(AuthContext);

    useEffect(() => {
        setName(props.partner.name);
        setProfession(props.partner.profession);
        setDescription(props.partner.description);
        setExternalUrl(props.partner.external_url);
        setCoupon(props.partner.coupon);
        setImageName(props.partner.partner_image);
        setImage(imagePrefix('PARTNERS', props.partner.partner_image));
        setExpirationDateStart(props.partner.expiration_date_start);
        setExpirationDateStart(props.partner.expiration_date_end);
    }, []);

    useEffect(() => {
        if (isUpdating) {
            updatePartner();
            setIsUpdating(false);
            setUpdatedImage(false);
        }
    }, [isUpdating])

    function handlePartnerNameChange(value: string){
        setName(value);
    }

    function handlePartnerProfessionChange(value: string){
        setProfession(value);
    }

    function handlePartnerDescriptionChange(value: string){
        setDescription(value);
    }

    function handlePartnerExternalLinkChange(value: string){
        setExternalUrl(value);
    }

    function handlePartnerCouponChange(value: string){
        setCoupon(value);
    }

    function handlePartnerExpirationDateStartChange(value: string) {
        setExpirationDateStart(value)
    }

    function handlePartnerExpirationDateEndChange(value: string) {
        setExpirationDateEnd(value)
    }

    function handlePartnerNameSave(value: boolean){
        props.setPartner(
           { ...props.partner, name: name }
        )

        setIsUpdating(true);
    }

    function handlePartnerProfessionSave(value: boolean){
        props.setPartner(
            { ...props.partner, profession: profession }
        )

        setIsUpdating(true);
    }

    function handlePartnerDescriptionSave(value: boolean){
        props.setPartner(
            { ...props.partner, description: description }
        )

        setIsUpdating(true);
    }

    function handlePartnerExternalLinkSave(value: boolean){
        props.setPartner(
            { ...props.partner, external_url: externalUrl }
        )

        setIsUpdating(true);
    }

    function handlePartnerCouponSave(value: boolean){
        props.setPartner(
            { ...props.partner, coupon: coupon }
        )

        setIsUpdating(true);
    }

    function handlePartnerExpirationStartSave(value: boolean) {
        props.setPartner(
            {
                ...props.partner, expiration_date_start: expirationDateStart
            }
        )

        setIsUpdating(true);
    }

    function handlePartnerExpirationEndSave(value: boolean) {
        props.setPartner(
            {
                ...props.partner, expiration_date_end: expirationDateEnd
            }
        )

        setIsUpdating(true);
    }

    function handleUploadImg(img: any){
        const form = new FormData();
        form.append("image", img);
        
        setImageName(img.name)
        setImage(URL.createObjectURL(img))
        setImageForm(form);

        setUpdatedImage(true);
    }

    // function handleUploadImg(img: File){
    //     const form = new FormData();
    //     form.append('image', img)
       
       
    // }

    function updateImage(partnerId: string) {
        APICaller
            .put(`/partners/image/${partnerId}`, imageForm, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`,
                    "Content-Type": 'multipart/form-data'
                }
            })
            .then((response: AxiosResponse) => {
                const updateImageResponse: IPartner = response.data.data;
                props.setPartner(updateImageResponse);
                setImage(imagePrefix('PARTNERS', updateImageResponse.partner_image));
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }
    
    function updatePartner() {
        if (props.partner.id === '') {
            APICaller
                .post('/partners/',
                    props.partner, 
                    {
                        headers: {
                            Authorization: `Bearer ${authContext?.getToken()}`
                        }
                    }
                ).then((response: AxiosResponse) => {
                    const createResponse: IPartner = response.data.data;
                    props.setPartner(createResponse);
                    alert('Parceiro criado com sucesso.');

                    if (updatedImage) {
                        updateImage(createResponse.id);
                    }                
                }).catch((error) => {
                    alert(error.response.data.message);
                })
        } else {
            APICaller
                .put(`/partners/${props.partner.id}`, 
                    props.partner,
                    {
                        headers: {
                            Authorization: `Bearer ${authContext?.getToken()}`
                        }
                    }
                ).then((response: AxiosResponse) => {
                    const updateResponse: IPartner = response.data.data;
                    props.setPartner(updateResponse);

                    if (updatedImage) {
                        updateImage(updateResponse.id);
                    }

                }).catch((error) => {
                    alert(error.response.data.message);
                })
        }
    }

    function handleDeletePartner() {
        if (props.partner && props.partner.id) {
            APICaller
                .delete(`/partners/${props.partner.id}`, {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`
                    }
                })
                .then((response: AxiosResponse) => {
                    props.onDelete();
                    props.setPartner(
                        {
                            id: '',
                            name: 'N/A',
                            profession: 'N/A',
                            description: 'N/A',
                            external_url: 'N/A',
                            coupon: 'N/A',
                            partner_image: 'N/A',
                            expiration_date_start: '1/1/1999',
                            expiration_date_end: '1/1/1999',
                            status: PartnerStatus.ACTIVE
                        }
                    );
                }).catch((error) => {
                    alert(error.response.data.message)
                })
        }
    }

    // function selectImg(){
    //     console.log("===================")
    //     console.log(image)
    //     if (image !== "") {
    //         return image
    //     } else {
    //         return G
    //     }
    // }

    return (
        <div id="partner-profile-main">
            <div id="partner-profile-section-title-container">
                <p id="partner-profile-section-title-text">
                    Dados do perfil 
                </p>
            </div>
            <div id="partner-profile-content-container">
                <div id="partner-profile-content">
                    <div id="partner-profile-content-img-container">
                        <img 
                            id="partner-profile-content-img"
                            src={image} 
                            alt="" 
                        />
                        <div id="partner-profile-content-img-upload-container">
                            <div id="partner-profile-content-img-upload">
                                <UploadFileBtn parent="-partner-profile" onUploadImg={handleUploadImg}/>
                            </div>
                        </div>
                    </div>
                    <div id="partner-profile-content-info-container">
                        <div className="partner-profile-content-info-text-input1">
                            <TextInput 
                                label="Nome" 
                                onValueChange={handlePartnerNameChange} 
                                onSaveBtnClick={handlePartnerNameSave}
                                value={props.partner.name}
                            />    
                        </div>
                        <div className="partner-profile-content-info-text-input1">
                            <TextInput 
                                label="Profissão" 
                                onValueChange={handlePartnerProfessionChange} 
                                onSaveBtnClick={handlePartnerProfessionSave}
                                value={props.partner.profession}
                            />    
                        </div>
                        <div className="partner-profile-content-info-text-input2">
                            <TextInputLarge 
                                label="Descrição"
                                onValueChange={handlePartnerDescriptionChange}
                                onSaveBtnClick={handlePartnerDescriptionSave}
                                value={props.partner.description}
                            />    
                        </div>
                        <div className="partner-profile-content-info-text-input1">
                            <TextInput 
                                label="Link Externo" 
                                onValueChange={handlePartnerExternalLinkChange} 
                                onSaveBtnClick={handlePartnerExternalLinkSave}
                                value={props.partner.external_url}
                            />    
                        </div>
                        <div className="partner-profile-content-info-text-input1">
                            <TextInput 
                                label="Cupom" 
                                onValueChange={handlePartnerCouponChange} 
                                onSaveBtnClick={handlePartnerCouponSave}
                                value={props.partner.coupon}
                            />    
                        </div>
                        <div className="partner-profile-content-info-text-input1">
                            <TextInput 
                                label="Válido de" 
                                onValueChange={handlePartnerExpirationDateStartChange} 
                                onSaveBtnClick={handlePartnerExpirationStartSave}
                                value={props.partner.expiration_date_start}
                            />    
                        </div>
                        <div className="partner-profile-content-info-text-input1">
                            <TextInput 
                                label="Válido até" 
                                onValueChange={handlePartnerExpirationDateEndChange} 
                                onSaveBtnClick={handlePartnerExpirationEndSave}
                                value={props.partner.expiration_date_end}
                            />    
                        </div>
                    </div>
                    <div id="partner-profile-content-delete">
                        <div id="partner-profile-content-delete-btn" onClick={handleDeletePartner}>
                            <p id="partner-profile-content-delete-btn-text">
                                DELETAR PARCEIRO
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}