import React, { useState } from "react"

import "./userRegisterModal.css"

import CloseCross           from "assets/icons/closeCross.png"
import TextInputWithoutSave from "../../input/textInputWithoutSave/textInputWithoutSave"
import PasswordInput        from "../../input/passwordInput/passwordInput"
import CheckBox             from "../../checkBox/checkBox"

import validateEmail from "misc/validation/validateEmail"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"

interface IRegisterError {
    field: string;
    message: string;
}

export default function UserRegisterModal(props: {onRegisterModalExitClick: Function, onAuthModalClick: Function }){

    const [login,            setLogin           ] = useState<string>("")
    const [password,         setPassword        ] = useState<string>("")
    const [confirmPassword,  setConfirmPassword ] = useState<string>("")
    const [email,            setEmail           ] = useState<string>("")
    const [tradeUrl,         setTradeUrl        ] = useState<string>("")
    const [discord,          setDiscord         ] = useState<string>("")    
    const [legalAge,         setLegalAge        ] = useState<boolean>(false)
    const [agreementsAccept, setAgreementsAccept] = useState<boolean>(false)
    const [error,            setError           ] = useState<IRegisterError>()

    function handleExitBtnClick(){
        props.onRegisterModalExitClick(false)
    }

    function handleAuthBtnClick(){
        props.onAuthModalClick(true)
    }

    async function handleLoginChange(value: string){
        setLogin(value)
    }
    
    function handlePasswordChange(value: string){
        setPassword(value)
    }

    function handleConfirmPasswordChange(value: string){
        setConfirmPassword(value)
    }

    function handleEmailChange(value: string){
        setEmail(value)
    }

    function handleTradeUrlChange(value: string){
        setTradeUrl(value)
    }

    function handleDiscordIdChange(value: string){
        setDiscord(value)
    }
    
    function handleAgreementsAcceptChange(value: boolean){
        setAgreementsAccept(!agreementsAccept);
    }
    
    function handleLegalAgeChange(value: boolean){
        setLegalAge(!legalAge);
    }

    function handleLoginClick() {
        props.onRegisterModalExitClick(false)
        props.onAuthModalClick(true)
    }

    function handleRegisterSubmit(){
        const isLoginValid = (agreementsAccept && legalAge);

        if (!isLoginValid) {
            if (!agreementsAccept)
                alert('Você deve estar de acordo com os Termos e Diretrizes e Políticas de Privacidade.');
            if (!legalAge)
                alert('Você deve possuir 18 anos ou mais para se registrar.');
        } else {
            if (password !== confirmPassword) {
                alert('As senhas não coincidem.')
            } else {
                APICaller
                    .post('/auth/signup', 
                        {
                            username: login,
                            password: password,
                            email: email,
                            trade_url: tradeUrl,
                            discord: discord
                        }
                    )
                    .then((response: AxiosResponse) => {
                        // const data = response.data.data;
                        alert(response.data.message)
                        props.onRegisterModalExitClick(false);
                    }).catch((error) => {

                    })
            }
        }
    }

    return (
        <div id="user-register-modal-main">
            <div id="user-register-modal-content">
                <div id="user-register-modal-exit-btn-container">
                    <div id="user-register-modal-exit-btn">
                        <img id="user-register-modal-exit-btn-img" src={CloseCross} onClick={handleExitBtnClick} draggable="false"/>
                    </div>
                </div>
                <div id="user-register-modal-input-container">
                    <div className="user-register-modal-input">
                        <TextInputWithoutSave 
                            label="Login" 
                            onValueChange={handleLoginChange}
                            onKeyDown={() => {}} 
                            value={login}
                        />
                    </div>
                    <div className="user-register-modal-input">
                        <PasswordInput 
                            label="Senha" 
                            onValueChange={handlePasswordChange} 
                            onKeyDown={() => {}}
                            value={password}
                        />
                    </div>
                    <div className="user-register-modal-input">
                        <PasswordInput 
                            label="Confirme a senha" 
                            onValueChange={handleConfirmPasswordChange} 
                            onKeyDown={() => {}}
                            value={confirmPassword}
                        />
                    </div>
                    <div className="user-register-modal-input">
                        <TextInputWithoutSave 
                            label="Email" 
                            onValueChange={handleEmailChange} 
                            onKeyDown={() => {}}
                            value={email} 
                        />
                    </div>
                    <div className="user-register-modal-input">
                        <TextInputWithoutSave 
                            label="URL de Troca (opcional)" 
                            onValueChange={handleTradeUrlChange} 
                            onKeyDown={() => {}}
                            value={tradeUrl} 
                        />
                    </div>
                    <div className="user-register-modal-input">
                        <TextInputWithoutSave 
                            label="ID do Discord (opcional)" 
                            onValueChange={handleDiscordIdChange} 
                            onKeyDown={() => {}}
                            value={discord}
                        />
                    </div>
                </div>
                <div id="user-register-modal-checkbox-container">
                    <div className="user-register-modal-checkbox-field">
                        <CheckBox 
                            text="Estou de acordo com os Termos e Diretrizes e Políticas de Privacidade"
                            onCheck={handleAgreementsAcceptChange}
                        />
                    </div>
                    <div className="user-register-modal-checkbox-field">
                        <CheckBox 
                            text="Sou maior de 18 anos"
                            onCheck={handleLegalAgeChange}
                        />
                    </div>
                </div>
                <div id="user-register-modal-btn-container">
                    <div id="user-register-modal-register-btn" onClick={handleRegisterSubmit}>
                        <p id="user-register-modal-register-btn-text">
                            Cadastrar
                        </p>
                    </div>
                    <p id="user-register-modal-login-btn-text" onClick={handleLoginClick}>
                        Login
                    </p>
                </div>
            </div>
        </div>
    )
}