import React, { useEffect, useState } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { IWalletBrickCustomization } from '@mercadopago/sdk-react/bricks/wallet/types';

const customization: IWalletBrickCustomization = {
    texts: {
        action: 'pay',
        valueProp: 'security_details'
    },
    visual: {
        buttonBackground: 'default',
        buttonHeight: '48px',
        borderRadius: '6px',
        valuePropColor: 'grey'
    },
    checkout: {
        theme: {
            elementsColor: '#4287F5',
            headerColor: '#4287F5'
        }
    }
}

export default function MercadoPagoWallet(props: {preferenceId: any}) {


    useEffect(() => {
        if (process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY){
            initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, {locale: "pt-BR"})
        }
    },[])


    return (
        <Wallet initialization={{preferenceId: props.preferenceId, redirectMode: 'self'}} customization={ customization }/>
    );
}