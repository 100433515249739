import React, { useContext, useState } from "react"

import "./joinRaffleModal.css"

import CloseCross           from "assets/icons/closeCross.png"
import { IRaffle } from "interfaces/IRaffle"
import { APICaller, imagePrefix } from "api/APICaller"
import { AuthContext } from "context/authProvider"
import { AxiosResponse } from "axios"

export default function JoinRaffleModal(props: {data: IRaffle, onJoinRaffleModalExitClick: Function}){
    
    const [ticketQuantity, setTicketQuantity] = useState<number>(0);
    const authContext = useContext(AuthContext);

    function handleTicketQuantityChange(value: any){
        if (/^\d+$/.test(value) === true || value === "") {
            setTicketQuantity(value)
        }
    }

    function handleConfirm(){
        if (ticketQuantity <= 0) {
            alert("Quantidade inválida de tickets. Favor inserir um valor maior ou igual a zero.")
        } else {
            const token = authContext?.getToken();

            if (!token) {
                alert("Você precisa estar logado para participar.")
            } else {
                APICaller
                .put(
                    `raffles/join/${props.data.id}`, {
                        tickets: ticketQuantity
                    }, 
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then((response: AxiosResponse) => {
                    alert(response.data.message);
                    props.onJoinRaffleModalExitClick(false);
                }).catch((error) => {
                    alert(error.response.data.message);
                })
            }
        }
    }

    function handleExitBtnClick(){
        props.onJoinRaffleModalExitClick(false)
    }

    return (
        <div id="join-raffle-modal-main">
            <div id="join-raffle-modal">
                <div id="join-raffle-modal-exit-btn-container">
                    <div id="join-raffle-modal-exit-btn">
                        <img 
                            id="join-raffle-modal-exit-btn-img2" 
                            src={CloseCross} 
                            onClick={handleExitBtnClick} 
                            draggable={false}
                        />
                    </div>
                </div>
                <div id="join-raffle-modal-content">
                    <p id="join-raffle-modal-content-info">
                        Selecione a quantidade de tickets para participar do sorteio
                    </p>
                    <div id="join-raffle-modal-content-img-container">
                        <img 
                            id="join-raffle-modal-content-img" 
                            src={imagePrefix('RAFFLES', props.data.item_image)}
                            alt="" 
                            draggable={false}
                        />
                    </div>
                    <p id="join-raffle-modal-content-item-name">
                        {props.data.item_name}
                    </p>
                    <div id="join-raffle-modal-content-price">
                        <p id="join-raffle-modal-content-price-label">
                            Valor do item
                        </p>
                        <div id="join-raffle-modal-content-price-wrapper">
                            <p id="join-raffle-modal-content-price-currency-txt">
                                R$
                            </p>
                            <p id="join-raffle-modal-content-price-txt">
                                {props.data.item_value}
                            </p>
                        </div>
                    </div>
                    <div id="join-raffle-modal-content-ticket-qnt">
                        <p id="join-raffle-modal-content-ticket-qnt-label">
                            Quantidade de tickets
                        </p>
                        <div id="join-raffle-modal-content-ticket-qnt-wrapper">
                            <input id="join-raffle-modal-content-ticket-qnt-field" value={ticketQuantity} onChange={(e) => {handleTicketQuantityChange(e.target.value)}}/>
                        </div>
                    </div>
                    <div id="join-raffle-modal-content-btn-container">
                        <div id="join-raffle-modal-content-btn" onClick={handleConfirm}>
                            <p id="join-raffle-modal-content-btn-txt">
                                Participar
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}