import React, { useContext, useEffect, useState } from "react"

import "./marketControl.css"  

import MarketControlSold from "./components/marketControlSold/marketControlSold"
import MarketControlForSale from "./components/marketControlForSale/marketControlForSale"
import IItem from "interfaces/IItem";
import { AuthContext } from "context/authProvider";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";


interface INavigationController {
    forSale: boolean;
    sold:    boolean;
}

export default function MarketControl() {
    const [uncheckedSoldItems, setUnheckedSoldItems] = useState<number>(0)
    const [navigationController, setNavigationController] = useState<INavigationController>({
        forSale: false,
        sold: false
    })
    const [soldItems, setSoldItems] = useState<IItem[]>([]);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>();
    const authContext = useContext(AuthContext);

    function getMarketSoldItems() {
        APICaller
            .get('/market/status?query=2', 
                {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`
                    }
                }
            ).then((response: AxiosResponse) => {
                const data: IItem[] = response.data.data;
                setSoldItems(data);
                setUnheckedSoldItems(data.length);
            }).catch((error) => {
                setSoldItems([]);
                setUnheckedSoldItems(0);
            });
    }

    useEffect(() => {
        getMarketSoldItems();
    }, [])

    useEffect(() => {
        if (shouldUpdate) {
            getMarketSoldItems();
            setShouldUpdate(false);
        }
    }, [shouldUpdate])

    function renderElement(element: string){
        if (element === "sold") {
            setNavigationController(
                {
                    sold:    true,
                    forSale: false,
                }
            )
        }
        if (element === "forSale") {
            setNavigationController(
                {
                    sold:    false,
                    forSale: true,
                }
            )
        }
    }

    return (
        <div id="market-control-main">
            <div id="market-control-section-title-container">
                <p id="market-control-section-title-text">
                    Mercado
                </p>
            </div>
            <div id="market-control-decoration"/>
            <div id="market-control-content">
                <div id="market-control-content-nav-container">
                    <div id="market-control-content-nav">
                        <div id="market-control-content-nav-btn1" className="market-control-content-nav-btn" onClick={() => {renderElement("forSale")}}>
                            <p className="market-control-content-nav-btn-txt">
                                Itens à venda
                            </p>
                        </div>
                        <div id="market-control-content-nav-btn2" className="market-control-content-nav-btn" onClick={() => {renderElement("sold")}}>
                            <div id="market-control-content-nav-btn2-notification">
                                <p id="market-control-content-nav-btn2-notification-txt">
                                    { uncheckedSoldItems }
                                </p>
                            </div>
                            <p id="market-control-content-nav-btn-txt-2" className="market-control-content-nav-btn-txt">
                                Itens vendidos
                            </p>
                        </div>
                    </div>
                </div>
                <div id="market-control-content-container">
                    {navigationController.forSale && (<MarketControlForSale/>)}
                    {navigationController.sold    && (<MarketControlSold items={soldItems} setItems={setSoldItems} setShouldUpdate={setShouldUpdate}/>)}
                </div>
            </div>
        </div>
    )
}