import React from "react"
import IUser from "interfaces/IUser"

import "./userCard.css"

import SteamLogo from "assets/icons/steamLogo.png"

export default function UserCard(props: {user: IUser, onMoreInfoClick: Function}){
    
    function handleClick(){
        props.onMoreInfoClick(props.user.id)
    }

    function handleSteamProfileClick(){
        
    }

    return (
        <li className="user-card-main">
            <div className="user-card-name">
                <p className="user-card-text">
                    {
                        props.user.persona_name
                    }
                </p>
            </div>
            <div className="user-card-email">
                <p className="user-card-text">
                    {
                        props.user.email
                    }
                </p>
            </div>
            <a href={props.user.profile_url} className="user-card-steam-link" target="_blank">
                <img className="user-card-steam-link-img" src={SteamLogo} onClick={handleSteamProfileClick}/>
            </a>
            <div className="user-card-more-info" onClick={handleClick}>
                <p className="user-card-text">
                    Mais informações
                </p>
            </div>
        </li>
    )
}