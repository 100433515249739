import React from "react"

import "./levelCard.css"
import { IRaffle } from "interfaces/IRaffle"
import { ILevel } from "interfaces/ILevel"
import FloatBar from "components/floatBar/floatBar"
import ShareIcon   from "assets/icons/card-icon-share.png"
import ViewIcon    from "assets/icons/card-icon-view.png"
import SteamIcon   from "assets/icons/card-icon-steam.png"
import { imagePrefix } from "api/APICaller"

import Logo from "assets/logo/logo-round.png"

export default function LevelCard(props: { raffle: IRaffle }){

    function floatValueToNameParser(float: number){
        if (float >= 0.00 && float < 0.07){
            return "Nova de fábrica"
        }
        else if (float >= 0.07 && float < 0.15){
            return "Pouco Usada"
        }
        else if (float >= 0.15 && float < 0.38){
            return "Testada em Campo"
        }
        else if (float >= 0.38 && float < 0.45){
            return "Bem desgastada"
        }
        else if (float >= 0.45 && float < 1){
            return "Veterana de Guerra"
        }
    }

    return (
        <li id="level-card-main">
            <div id="level-card-item">
                <div id="level-card-item-info">
                    <div id="level-card-item-info-name-container">
                        <p id="level-card-item-info-name-text">
                            {props.raffle?.item_name}
                        </p>
                    </div>
                    <div id="level-card-item-info-aditional">
                        <div id="level-card-item-info-aditional-float">
                            <p id="level-card-item-info-aditional-float-name">
                                {floatValueToNameParser(props.raffle?.item_float)}
                            </p>
                            <p id="level-card-item-info-aditional-float-separator">
                                |
                            </p>
                            <p id="level-card-item-info-aditional-float-value">
                                Float: {props.raffle?.item_float}
                            </p>
                        </div>
                        <div id="level-card-item-info-aditional-float-bar-container">
                            <FloatBar float={props.raffle?.item_float}/>
                        </div>
                    </div>
                    <div id="level-card-item-info-value-container">
                        <p id="level-card-item-info-value-label-txt">
                            Valor:
                        </p>
                        <p id="level-card-item-info-value-txt">
                            R$ { props.raffle.item_value }
                        </p>
                    </div>
                    <div id="level-card-item-info-action-buttons">
                        <div id="level-card-item-info-action-buttons-join-btn">
                            <p id="level-card-item-info-action-buttons-join-txt">
                                Participar
                            </p>
                        </div>
                        <a id="level-card-item-info-action-buttons-steam-btn" href={props.raffle.item_svc}>
                            <img className="level-card-item-info-action-buttons-img" src={SteamIcon} alt="" />
                        </a>
                        {/* <div id="level-card-item-info-action-buttons-visualize-btn">
                            <img className="level-card-item-info-action-buttons-img" src={ViewIcon} alt="" />
                        </div> */}
                        <div id="level-card-item-info-action-buttons-share-btn">
                            <img className="level-card-item-info-action-buttons-img" src={ShareIcon} alt="" />
                        </div>
                    </div>
                </div>
                <div id="level-card-item-img-container">
                    {
                        props.raffle?.item_image && 
                        <img 
                            id="level-card-item-img" 
                            src={imagePrefix('RAFFLES', props.raffle.item_image)} 
                            alt="" 
                            draggable={false}
                        />
                    }
                    {
                        !props.raffle.item_image && 
                        <img 
                            id="level-card-item-img" 
                            src={Logo} 
                            alt="" 
                            draggable={false}
                        />
                    }
                </div>
            </div>
            <div id="level-card-progress">
                <div id="level-card-progress-value">
                    <p id="level-card-progress-value-txt">
                        {props.raffle?.item_percentage}%
                    </p>
                </div>
                <div id="level-card-progress-bar-container">
                    <div id="level-card-progress-bar">
                        <div id="level-card-progress-bar-filled" style={{width: (`${props.raffle?.item_percentage}%`)}}/>
                    </div>
                </div>
            </div>
        </li>
    )
}