import React from "react"

import "./winnerSection.css"

import G from "assets/Gesicht.png"
import IUser from "interfaces/IUser"
import IDate from "interfaces/IDate"
import ITime from "interfaces/ITime"

export default function WinnerSection(
    props: {
        winnerData: IUser | undefined, dateData: string, timeData: string
    }
){
    return (
        <div id="winner-section-main">
            <div id="winner-section-title">
                <p id="winner-section-title-txt">
                    VENCEDOR
                </p>
            </div>
            <div id="winner-section-timestamp">
                <p id="winner-section-timestamp-txt">
                    {props.dateData} {props.timeData}
                </p>
            </div>
            <div id="winner-section-main-content">
                <div id="winner-section-main-content-img-container">
                    <img
                        id="winner-section-main-content-img" 
                        src={G}
                        alt="" 
                        draggable={false}
                    />
                </div>
                <div id="winner-section-main-content-data-container">
                    <div className="winner-section-main-content-data">
                        <p className="winner-section-main-content-data-txt">
                            {props.winnerData?.persona_name}
                        </p>
                    </div>
                    <div className="winner-section-main-content-data">
                        <p className="winner-section-main-content-data-txt">
                            {props.winnerData?.email}
                        </p>
                    </div>
                    <div id="winner-section-main-content-profile-link">
                        <a 
                            id="winner-section-main-content-profile-link-anchor" 
                                href=""
                                target="_blank" 
                                rel="noreferrer noopener"
                            >
                            <p id="winner-section-main-content-profile-link-txt">
                                IR PARA O PERFIL DA STEAM
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}