import React, {useState, useEffect} from "react"

import "./textInputLarge.css"

export default function TextInputLarge(props: {label:string, value?: string, onValueChange: Function, onSaveBtnClick: Function}){

    const [value, setValue] = useState<string>("")
    const [saveBtnClick, setSaveBtnClick] = useState<boolean>(false)

    useEffect (() => {
        setValue(props.value || "")
    }, [props.value])

    function handleValueChange(value: string){

        if (value.length <= 380){
            setValue(value)
            props.onValueChange(value)
        }

    }

    function handleSaveBtnClick(value: boolean){
        setSaveBtnClick(value)
        props.onSaveBtnClick(value)
    }

    return (
        <div id="text-input-large-main">
            <div id="text-input-large-label-container">
                <p id="text-input-large-label">
                    {props.label}
                </p>
            </div>
            <div id="text-input-large-container">
                <div id="text-input-large-text">
                    <textarea id="text-input-large-text-field" rows={4} onChange={(e) => {handleValueChange(e.target.value)}} value={value}/>
                </div>
            </div>
            <div id="text-input-large-btn-container">
                <div id="text-input-large-btnx" onClick={(e) => {handleSaveBtnClick(true)}}>
                    <p id="text-input-large-btn-text">
                        Salvar
                    </p>
                </div>
            </div>
        </div>
    )
}