import React from "react"

import "./partnerDescriptionCard.css"

import  { IPartner } from "interfaces/IPartner"
import { imagePrefix } from "api/APICaller"

export default function PartnerDescriptionCard(props: {data: IPartner}){
    return (
        <li id="partner-description-card-main">
            <div id="partner-description-card-img-container">
                <img id="partner-description-card-img-sub-container" src={imagePrefix("PARTNERS", props.data.partner_image)}/>
            </div>
            <div id="partner-description-card-info">
                <div id="partner-description-card-info-name-container">
                    <p id="partner-description-card-info-name-text">
                        {props.data.name}
                    </p>
                </div>
                <div id="partner-description-card-info-ocupation-container">
                    <p id="partner-description-card-info-ocupation-text">
                        {props.data.profession}
                    </p>
                </div>
                <div id="partner-description-card-info-description-container">
                    <p id="partner-description-card-info-description-text">
                        {props.data.description}
                    </p>
                </div>
                <div id="partner-description-card-info-link-container">
                    <a id="partner-description-card-info-link-text"  href={props.data.external_url}>
                        {props.data.external_url }
                    </a>
                </div>
            </div>
        </li>

    )
}