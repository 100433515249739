import React, { useState } from "react"

import "./FAQ.css"

import Header                from "components/header/header"
import Footer                from "components/footer/footer"
import FaqQuestionAnswerCard from "./components/faqQuestionAnswerCard"
import SteamLoginModal       from "components/modal/steamLoginModal/steamLoginModal"
import UserLoginModal        from "components/modal/userLoginModal/userLoginModal"
import UserRegisterModal     from "components/modal/userRegisterModal/userRegisterModal"
import BuyTicketModal        from "components/modal/buyTicketModal/buyTicketModal"


export default function FAQ(){

    const [renderSteamLoginModal, setRenderSteamLoginModal] = useState<boolean>(false)
    const [renderLoginModal,      setRenderLoginModal    ] = useState<boolean>(false)
    const [renderRegisterModal,   setRenderRegisterModal ] = useState<boolean>(false)
    const [renderBuyTicketModal,  setRenderBuyTicketModal] = useState<boolean>(false)

    function steamLoginModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(false)
            setRenderSteamLoginModal(true)   
        }
        if (value === false) {
            setRenderSteamLoginModal(false)   
        }
    }

    function authModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(true)   
        }
        if (value === false) {
            setRenderLoginModal(false)   
        }
    }

    function registerModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(false)
            setRenderRegisterModal(true)   
        }
        if (value === false) {
            setRenderRegisterModal(false)   
        }
    }

    function buyTicketModalController(value: boolean){
        if (value === true) {
            setRenderBuyTicketModal(true)   
        }
        if (value === false) {
            setRenderBuyTicketModal(false)   
        }
    }

    return (
        <>
            <Header renderAuthScreen={authModalController}/>
            <div id="faq-page-faq">
                <div id="faq-page-title">
                    <p id="faq-page-title-txt">
                        FAQ
                    </p>
                </div>
                <div id="faq-page-content">
                    {/* <div id="faq-page-content-menu">
                        <div id="faq-page-content-menu-content">

                        </div>
                    </div> */}
                    <ul id="faq-page-content-list">
                        <FaqQuestionAnswerCard answer="" question=""/>
                    </ul>
                </div>
                <Footer/>
            </div>
            {
                renderSteamLoginModal &&
                (
                    <SteamLoginModal 
                        onSteamLoginModalExitClick={steamLoginModalController}
                    />
                )
            }
            {   
                renderLoginModal &&
                (
                    <UserLoginModal 
                        onAuthModalExitClick={authModalController} 
                        onRegisterModalClick={registerModalController}
                        onSteamLoginModalClick={steamLoginModalController}
                    />
                )
            }
            {   
                renderRegisterModal &&
                (
                    <UserRegisterModal 
                        onRegisterModalExitClick={registerModalController}
                        onAuthModalClick={authModalController}
                    />
                )
            }
            {
                renderBuyTicketModal &&
                (
                    <BuyTicketModal 
                        onBuyTicketModalExitClick={buyTicketModalController}
                    />
                )
            }
            <div id="main-background"/>
        </>
    )
}