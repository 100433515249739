import React from "react"

import "./marketItemCard.css"


import IItem     from "interfaces/IItem"
import ShareIcon from "assets/icons/card-icon-share.png"
import ViewIcon  from "assets/icons/card-icon-view.png"
import SteamIcon from "assets/icons/card-icon-steam.png"

import FloatBar  from "components/floatBar/floatBar"

import AK from "../../../../assets/ak.png"
import { imagePrefix } from "api/APICaller"

export default function MarketItemCard(props: {data: IItem, onBuy: Function}){

    function handleBuyBtnClick(){
        props.onBuy(props.data.id)
    }

    function floatValueToNameParser(float: number){
        if (float >= 0.00 && float < 0.07){
            return "Nova de fábrica"
        }
        else if (float >= 0.07 && float < 0.15){
            return "Pouco Usada"
        }
        else if (float >= 0.15 && float < 0.38){
            return "Testada em Campo"
        }
        else if (float >= 0.38 && float < 0.45){
            return "Bem desgastada"
        }
        else if (float >= 0.45 && float < 1){
            return "Veterana de Guerra"
        }
    }

    return (
        <li id="market-item-card-main" key={props.data.id}>
            <div id="market-item-card-info">
                <div id="market-item-card-info-name-container">
                    <p id="market-item-card-info-name">
                        {props.data.item_name}
                    </p>
                </div>
                <div id="market-item-card-info-img-container">
                    <img
                        id="market-item-card-info-img" 
                        src={imagePrefix('MARKET', props.data.item_image)} 
                        alt="" 
                        draggable={false}
                    />
                </div>
                <div id="market-item-card-info-price-container">
                    <p id="market-item-card-info-price-currency">
                        R$
                    </p>
                    <p id="market-item-card-info-price">
                        {props.data.price}
                    </p>
                </div>
                <div id="market-item-card-info-kick-btns-container">
                    <a 
                        id="market-item-card-info-kick-btn" 
                        target="_blank" 
                        rel="noreferrer noopener" 
                        href={props.data.inspection_url}
                    >
                        <img src={SteamIcon} alt="" />
                    </a>
                    {/* <a 
                        id="market-item-card-info-kick-btns-visualize"
                        target="_blank" 
                        rel="noreferrer noopener" 
                    >
                        <img src={ViewIcon} alt="" />
                    </a> */}
                    <a 
                    id="market-item-card-info-kick-btn"
                        target="_blank" 
                        rel="noreferrer noopener" 
                    >
                        <img src={ShareIcon} alt="" />
                    </a>
                </div>
                <div id="market-item-card-info-float">
                    <div id="market-item-card-info-float-status-container">
                        <div id="market-item-card-info-float-status">
                            <p id="market-item-card-info-float-status-text">
                                {floatValueToNameParser(props.data.item_float)}
                            </p>
                        </div>
                    </div>
                    <div id="market-item-card-info-float-value-container">
                        <p id="market-item-card-info-float-value-text1">
                            Float
                        </p>
                        <p id="market-item-card-info-float-value-text2">
                            {props.data.item_float}
                        </p>
                    </div>
                    <div id="market-item-card-info-float-bar-container">
                        <FloatBar float={props.data.item_float}/>
                    </div>
                </div>
            </div>
            <div id="market-item-card-btn">
                <div id="market-item-card-btn-buy" onClick={handleBuyBtnClick}>
                    <p id="market-item-card-btn-buy-text">
                        Comprar
                    </p>
                </div>
            </div>
        </li>
    )
}