import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from 'context/authProvider';


export default function UserProtectedRoute(children: any){
    const authContext = useContext(AuthContext)

    if (authContext?.isAuthenticated === true && !authContext.isAdministrator) {
        return <Outlet />;
    } else {
        return <Outlet />;
    }
}
