import React, { useState, useEffect } from "react"

import "./partners.css"

import Header                 from "components/header/header"
import Footer                 from "components/footer/footer"
import SteamLoginModal        from "components/modal/steamLoginModal/steamLoginModal"
import UserLoginModal         from "components/modal/userLoginModal/userLoginModal"
import UserRegisterModal      from "components/modal/userRegisterModal/userRegisterModal"
import BuyTicketModal         from "components/modal/buyTicketModal/buyTicketModal"
import PartnerDescriptionCard from "./components/partnerDescriptionCard"
import { IPartner }               from "interfaces/IPartner"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"

export default function Partners(){

    const [renderSteamLoginModal, setRenderSteamLoginModal] = useState<boolean>(false)
    const [renderLoginModal,      setRenderLoginModal    ] = useState<boolean>(false)
    const [renderRegisterModal,   setRenderRegisterModal ] = useState<boolean>(false)
    const [renderBuyTicketModal,  setRenderBuyTicketModal] = useState<boolean>(false)

    const [partnerList, setPartnerList] = useState<IPartner[]>([])

    useEffect(() => {
        APICaller
            .get('/partners')
            .then((response: AxiosResponse) => {
                const data: IPartner[] = response.data.data;
                setPartnerList(data);
            }).catch((error) => {
                alert(error.response.data.message)
            })
    },[])

    function steamLoginModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(false)
            setRenderSteamLoginModal(true)   
        }
        if (value === false) {
            setRenderSteamLoginModal(false)   
        }
    }

    function authModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(true)   
        }
        if (value === false) {
            setRenderLoginModal(false)   
        }
    }

    function registerModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(false)
            setRenderRegisterModal(true)   
        }
        if (value === false) {
            setRenderRegisterModal(false)   
        }
    }

    function buyTicketModalController(value: boolean){
        if (value === true) {
            setRenderBuyTicketModal(true)   
        }
        if (value === false) {
            setRenderBuyTicketModal(false)   
        }
    }

    return (
        <>
            <Header renderAuthScreen={authModalController} renderBuyTicketScreen={buyTicketModalController}/>
            <div id="partners-page-partners">
                <ul id="partners-page-content">
                    <li id="partners-page-title" key={999999}>
                        <p id="partners-page-title-text">
                            Parceiros
                        </p>
                    </li>
                    {
                        partnerList.map(partner => {
                            return (
                                <PartnerDescriptionCard 
                                    key={partner.id}
                                    data={partner}
                                />
                            )
                        })
                    }
                </ul>
                <Footer/>
            </div>
            {
                renderSteamLoginModal &&
                (
                    <SteamLoginModal 
                        onSteamLoginModalExitClick={steamLoginModalController}
                    />
                )
            }
            {   
                renderLoginModal &&
                (
                    <UserLoginModal 
                        onAuthModalExitClick={authModalController} 
                        onRegisterModalClick={registerModalController}
                        onSteamLoginModalClick={steamLoginModalController}
                    />
                )
            }
            {   
                renderRegisterModal &&
                (
                    <UserRegisterModal 
                        onRegisterModalExitClick={registerModalController}
                        onAuthModalClick={authModalController}
                    />
                )
            }
            {
                renderBuyTicketModal &&
                (
                    <BuyTicketModal 
                        onBuyTicketModalExitClick={buyTicketModalController}
                    />
                )
            }
            <div id="main-background"/>
        </>
    )
}