import React, { useEffect, useState } from "react"

import "./raffle.css"

import Header             from "components/header/header"
import Footer             from "components/footer/footer"
import UserProfilePicture from "./components/userProfilePicture/userProfilePicture"
import RaffleItemCard     from "./components/raffleItemCard/raffleItemCard"
import BuyTicketModal     from "components/modal/buyTicketModal/buyTicketModal"
import SteamLoginModal   from "components/modal/steamLoginModal/steamLoginModal"
import UserLoginModal     from "components/modal/userLoginModal/userLoginModal"
import UserRegisterModal  from "components/modal/userRegisterModal/userRegisterModal"

import RingImage          from "assets/misc/ring.png"
import DiscordIcon        from "assets/icons/discord.png"

import IUser              from "interfaces/IUser"
import { ILevel }             from "interfaces/ILevel"
import { APICaller } from "api/APICaller"
import { AxiosResponse } from "axios"
import { IRaffle } from "interfaces/IRaffle"
import INormalUser from "interfaces/INormalUser"

import Logo from 'assets/logo/logo-round.png'

export default function Raffle(){

    const [renderSteamLoginModal, setRenderSteamLoginModa] = useState<boolean>(false)
    const [renderLoginModal, setRenderLoginModal] = useState<boolean>(false)
    const [renderRegisterModal, setRenderRegisterModal] = useState<boolean>(false)
    const [renderBuyTicketModal, setRenderBuyTicketModal] = useState<boolean>(false)
    const [levels, setLevels] = useState<ILevel[]>([]);
    const [raffles, setRaffles] = useState<IRaffle[]>([]);
    // const [winner, setWinner] = useState<>();
    const [currentLevel, setCurrentLevel] = useState<ILevel>();
    const [currentRaffle, setCurrentRaffle] = useState<IRaffle>();
    const [userParticipants, setUserParticipants] = useState<INormalUser[]>([]);
    const [steamParticipants, setSteamParticipants] = useState<IUser[]>([]);

    useEffect(() => {
        APICaller
            .get('/levels')
            .then((response: AxiosResponse) => {
                const data: ILevel[] = response.data.data;

                APICaller
                    .post('/raffles/search', 
                        data.map((l) => l.id)
                    )
                    .then((response: AxiosResponse) => {
                        const raffleData: IRaffle[] = response.data.data;
                        setRaffles(raffleData)
                        setCurrentRaffle(raffleData[0]);
                        


                    }).catch((error) => {
                        alert(error.response.data.message)
                    })
                setLevels(data);
            }).catch((error) => {
                setLevels([]);
            })
    }, []);

    useEffect(() => {
        if (currentRaffle) {
            APICaller
                .get(`/raffles/${currentRaffle.level_id}/participants`)
                .then((response: AxiosResponse) => {
                    const data = response.data.data;
                    setUserParticipants(data.users);
                    setSteamParticipants(data.steam_users);
                })
                .catch((error) => {
                    setUserParticipants([]);
                    setSteamParticipants([]);
                })

                setCurrentLevel(levels.find((l) => l.id == currentRaffle.level_id));
        }
    }, [currentRaffle]);

    function steamLoginModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(false)
            setRenderSteamLoginModa(true)   
        }
        if (value === false) {
            setRenderSteamLoginModa(false)   
        }
    }

    function authModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(true)   
        }
        if (value === false) {
            setRenderLoginModal(false)   
        }
    }
    
    function registerModalController(value: boolean){
        if (value === true) {
            setRenderLoginModal(false)
            setRenderRegisterModal(true)   
        }
        if (value === false) {
            setRenderRegisterModal(false)   
        }
    }

    function buyTicketModalController(value: boolean){
        if (value === true) {
            setRenderBuyTicketModal(true)   
        }
        if (value === false) {
            setRenderBuyTicketModal(false)   
        }
    }

    return (
        <>
            <Header renderAuthScreen={authModalController} renderBuyTicketScreen={buyTicketModalController}/>
            <div id="raffle-main">
                <div id="raffle-raffler">
                    <div id="raffle-raffler-item-card-container">
                        { currentLevel &&
                          currentRaffle &&
                          <RaffleItemCard level={currentLevel} raffle={currentRaffle}/>
                        }
                    </div>
                    <div id="raffle-raffler-animation-container">

                    </div>
                    { 
                        currentLevel &&
                        currentRaffle && currentRaffle.winner && currentRaffle.winner !== '' && 
                        <div id="raffle-raffler-winner-container">
                            <p id="raffle-raffler-winner-label">
                                Vencedor
                            </p>
                            <div id="raffle-raffler-winner-img-container">
                                <img id="raffle-raffler-winner-img" src={RingImage} alt="" draggable={false}/>
                            </div>
                            <p id="raffle-raffler-winner-name-txt">
                                { }
                            </p>
                            <div id="raffle-raffer-winner-discord-info">
                                <img id="raffle-raffler-winner-discord-img" src={DiscordIcon} alt="" draggable={false} />
                                <p id="raffle-raffler-winner-discord-label">
                                    Discord ID
                                </p>
                                <p id="raffle-raffler-winner-discord-id">
                                    asdasdasdasd
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <div id="raffle-users">
                    <p id="raffle-users-title-txt">Participantes</p>
                    <ul id="raffle-users-list">
                        {
                            steamParticipants.length > 0 &&
                            steamParticipants.map((steam) => <UserProfilePicture image={steam.avatar_medium} url={steam.profile_url}/>)
                        }
                        {
                            userParticipants.length > 0 &&
                            userParticipants.map((users) => <UserProfilePicture image={Logo} url={users.discord}/>)
                        }
                    </ul>
                    <p id="raffle-users-info-txt">Clique na foto para identificar o perfil do participante no Discord</p>
                </div>
                <Footer/>
            </div>
            {
                renderSteamLoginModal &&
                (
                    <SteamLoginModal 
                        onSteamLoginModalExitClick={steamLoginModalController}
                    />
                )
            }
            {   
                renderLoginModal &&
                (
                    <UserLoginModal 
                        onAuthModalExitClick={authModalController} 
                        onRegisterModalClick={registerModalController}
                        onSteamLoginModalClick={steamLoginModalController}
                    />
                )
            }
            {   
                renderRegisterModal &&
                (
                    <UserRegisterModal 
                        onRegisterModalExitClick={registerModalController}
                        onAuthModalClick={authModalController}
                    />
                )
            }
            {
                renderBuyTicketModal &&
                (
                    <BuyTicketModal 
                        onBuyTicketModalExitClick={buyTicketModalController}
                    />
                )
            }
        </>
    )
}