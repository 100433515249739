import React from "react"

import "./normalUserCard.css"

import INormalUser from "interfaces/INormalUser"
import Logo from 'assets/logo/logo-round.png'

export default function NormalUserCard(props: {user: INormalUser, onMoreInfoClick: Function}){
    
    function handleClick(){
        props.onMoreInfoClick(props.user.id)
    }

    function handleSteamProfileClick(){
        
    }

    return (
        <li className="user-card-main">
            <div className="user-card-name">
                <p className="user-card-text">
                    {
                        props.user.username
                    }
                </p>
            </div>
            <div className="user-card-email">
                <p className="user-card-text">
                    {
                        props.user.discord
                    }
                </p>
            </div>
            <a href={props.user.trade_url} className="user-card-steam-link" target="_blank">
                <img className="user-card-steam-link-img" src={Logo} onClick={handleSteamProfileClick}/>
            </a>
            <div className="user-card-more-info" onClick={handleClick}>
                <p className="user-card-text">
                    Mais informações
                </p>
            </div>
        </li>
    )
}