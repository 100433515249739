import React, {useContext, useEffect, useState} from "react"

import "./adminConnected.css"

import Article  from "assets/userHeaderMenuDropdownIcons/article.png"
import Calendar from "assets/userHeaderMenuDropdownIcons/calendar.png"
import Case     from "assets/userHeaderMenuDropdownIcons/case.png"
import Exit     from "assets/userHeaderMenuDropdownIcons/exit.png"
import Help     from "assets/userHeaderMenuDropdownIcons/help.png"
import Settings from "assets/userHeaderMenuDropdownIcons/settings.png"
import Support  from "assets/userHeaderMenuDropdownIcons/support.png"
import Discord  from "assets/userHeaderMenuDropdownIcons/discord.png"

import Chevron from "assets/icons/left-arrow.png"
import Shield from "assets/icons/adminShield.png"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "context/authProvider"

export default function AdminConnected(){

    const [width, setWidth] = useState<number>(0)

    const [buttonHover, setButtonHover] = useState<boolean>(false)
    const [dropdownHover, setDropdownHover] = useState<boolean>(false)

    const [renderDropDown, setRenderDropDown] = useState<boolean>(false)

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [position, setPosition] = useState<any>({positionX: 0, positionY: 0})
    const authProvider = useContext(AuthContext)
    const navigate = useNavigate()

    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        setRenderDropDown(false)
    }, [windowDimensions]);
    
    useEffect(() => {
        const elemento = document.getElementById('admin-connected');
        if (elemento != null) {
            const rect = elemento.getBoundingClientRect();
            setWidth(rect.width)
            setPosition({
                X: rect.left,
                Y: rect.top + rect.height
            })
        }
        
    }, [window.innerWidth,window.innerHeight])
    
    function handleClick(id: number){
        // props.onLevelClick(id)
    }

    function handleRenderDropdown(){
        if (renderDropDown == true){
            setRenderDropDown(false)
        } else {
            setRenderDropDown(true)
        }
    }

    function backgroundColorController(){
        if (renderDropDown === true){
            return {
                borderBottomLeftRadius: "0px", 
                borderBottomRightRadius: "0px",
                borderRight: "3px #162D46 solid",
                borderLeft: "3px #162D46 solid",
                borderTop: "3px #162D46 solid"
            }
        } else {
            return {
                borderBottomLeftRadius: "10px", 
                borderBottomRightRadius: "10px",
            }
        }
    }

    function handleLogoff() {
        authProvider?.rmToken();
        navigate("/");
    }

    return (
        <div id="admin-connected-main">
            <div id="admin-connected" onClick={handleRenderDropdown} style={backgroundColorController()}>
                <div id="admin-connected-img-container">
                    <img 
                        id="admin-connected-img" 
                        src={Shield} 
                        alt=""
                        draggable={false}
                    />
                </div>
                <div id="admin-connected-name">
                    <p id="admin-connected-name-txt">
                        Administrador
                    </p>
                </div>
                <div id="admin-connected-chevron">
                    <div id="admin-connected-chevron-box">
                        <img 
                            id="admin-connected-chevron-img" 
                            className={renderDropDown? "admin-connected-chevron-rotate": "admin-connected-chevron-rotate-inverse"}
                            src={Chevron} 
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <ul id="admin-connected-menu" 
                style={{display: renderDropDown? "flex": "none", top: position.Y, left: position.X, width: width}}
            >
                <li className="admin-connected-menu-entry" key={1}>
                    <a className="admin-connected-menu-entry-link" href="/admin/controle">
                        <div className="admin-connected-menu-entry-link-img-container">
                            <img className="admin-connected-menu-entry-link-img" src={Settings} alt="" />
                        </div>
                        <p className="admin-connected-menu-entry-link-txt">
                            Painel de controle
                        </p>
                    </a>
                </li>
                <li className="admin-connected-menu-entry" id="admin-connected-menu-entry-exit" key={2} onClick={handleLogoff}>
                    <div className="admin-connected-menu-entry-link-img-container">
                        <img className="admin-connected-menu-entry-link-img" src={Exit} alt="" />
                    </div>
                    <p className="admin-connected-menu-entry-link-txt">
                        Sair
                    </p>
                </li>
                
            </ul>
        </div>
    )
}