import React, { useContext, useEffect, useState } from "react"
import IUser from "interfaces/IUser"

import TextInput from "components/input/textInput/textInput"

import SilverTicket from 'assets/icons/silverTicket.png'
import TrashCan      from "assets/icons/trashCan.png"
import DownloadArrow from "assets/icons/downloadArrow.png"

import "./userProfileAdmin.css"
import { APICaller } from "api/APICaller"
import { AuthContext } from "context/authProvider"
import { AxiosResponse } from "axios"

export default function UserProfileAdmin(props: {user: IUser | undefined}) {

    const [tickets, setTickets] = useState<number>(0);
    
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [tradeUrl, setTradeUrl] = useState<string>('');

    const authContext = useContext(AuthContext);

    function handleTicketChange(value: any){
        if (/^\d+$/.test(value) === true || value === "") {
            setTickets(value);
        }
    }

    function handleNameChange(value: string) {
        setName(value)
    }

    function handleEmailChange(value: string) {
        setEmail(value)
    }

    function handleTradeUrlChange(value: string) {
        setTradeUrl(value)
    }

    function handleTicketSave(value: boolean){
        APICaller
            .put(`/wallets/${props.user?.id}`, {
                tickets: tickets
            }, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                const data: number = response.data.data;
                setTickets(data);
                alert(response.data.message);
            })
    }
  
    useEffect(() => {
        APICaller
            .get(`/wallets/${props.user?.id}`, {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                const data: number = response.data.data;
                setTickets(data);
            }).catch((error) => {
                alert(error.response.data.message)
            })
    }, [])

    return (
        <div id="user-profile-admin-main">
            <div id="user-profile-admin-section-title-container">
                <p id="user-profile-admin-section-title-text">
                    Dados do usuário
                </p>
            </div>
            <div id="user-profile-admin-decoration"/>
            <div id="user-profile-admin-content-container">
                <div id="user-profile-admin-content">
                    <div id="user-profile-admin-content-primary-info">
                        <div className="user-profile-admin-content-primary-info-container">
                            <TextInput
                                label="Tickets"
                                onValueChange={handleTicketChange} 
                                onSaveBtnClick={handleTicketSave}
                                value={tickets}
                            />
                        </div>
                        <div className="user-profile-admin-content-primary-info-container">
                            <TextInput 
                                label="Nome"
                                onValueChange={handleNameChange} 
                                onSaveBtnClick={handleTicketSave}
                                value={props.user?.persona_name}
                            />
                        </div>
                        <div className="user-profile-admin-content-primary-info-container">
                            <TextInput 
                                label="Email"
                                onValueChange={handleEmailChange} 
                                onSaveBtnClick={handleTicketSave}
                                value={props.user?.email}
                            />
                        </div>
                        <div className="user-profile-admin-content-primary-info-container">
                            <TextInput 
                                label="URL de Troca"
                                onValueChange={handleTradeUrlChange} 
                                onSaveBtnClick={handleTicketSave}
                                value={props.user?.trade_url}
                            />
                        </div>
                        {/* <div className="user-profile-admin-content-primary-info-container">
                            <TextInput 
                                label="Código"
                                onValueChange={handleTicketChange} 
                                onSaveBtnClick={handleTicketSave}
                            />
                        </div> */}
                        
                    </div>
                    <div id="user-profile-admin-content-secondary-info">
                        <div id="user-profile-admin-content-secondary-info-tickets">
                            <div id="user-profile-admin-content-secondary-info-tickets-content">
                                <div id="user-profile-admin-content-secondary-info-tickets-content-img-container">
                                    <img id="user-profile-admin-content-secondary-info-tickets-content-img" src={SilverTicket} alt="" />
                                </div>
                                <div id="user-profile-admin-content-secondary-info-tickets-content-value">
                                    <p id="user-profile-admin-content-secondary-info-tickets-content-value-text">
                                        { tickets }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="user-profile-admin-content-secondary-info-history">
                            <div id="user-profile-admin-content-secondary-info-history-title">
                                <p id="user-profile-admin-content-secondary-info-history-title-txt">
                                    Histórico da conta
                                </p>
                            </div>
                            <div id="user-profile-admin-content-secondary-info-history-decoration"/>
                            <div id="user-profile-admin-content-secondary-info-history-label">
                                <p id="user-profile-admin-content-secondary-info-history-label-text-date">
                                    DATA
                                </p>
                                <p id="user-profile-admin-content-secondary-info-history-label-text-type">
                                    TIPO
                                </p>
                                <p id="user-profile-admin-content-secondary-info-history-label-text-tickets">
                                    TICKETS
                                </p>
                                <p id="user-profile-admin-content-secondary-info-history-label-text-value">
                                    VALOR
                                </p>
                            </div>
                            <ul id="user-profile-admin-content-secondary-info-history-list">
                                {/* <UserProfileAdminHistoryCard data={{id:"aaa", time: "22:23", date: "20/20/2020", type: "addBox", tickets: 20, value: 20000}}/>
                                <UserProfileAdminHistoryCard data={{id:"aaa", time: "22:23", date: "20/20/2020", type: "addBox", tickets: 20, value: 20000}}/>
                                <UserProfileAdminHistoryCard data={{id:"aaa", time: "22:23", date: "20/20/2020", type: "addBox", tickets: 20, value: 20000}}/>
                                <UserProfileAdminHistoryCard data={{id:"aaa", time: "22:23", date: "20/20/2020", type: "addBox", tickets: 20, value: 20000}}/>
                                <UserProfileAdminHistoryCard data={{id:"aaa", time: "22:23", date: "20/20/2020", type: "addBox", tickets: 20, value: 20000}}/>
                                <UserProfileAdminHistoryCard data={{id:"aaa", time: "22:23", date: "20/20/2020", type: "addBox", tickets: 20, value: 20000}}/> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="user-profile-admin-action-btn-container">
                    <div id="user-profile-admin-action-btn-delete-account">
                        <p className="user-profile-admin-action-btn-text">
                            EXCLUIR CONTA
                        </p>
                        <div className="user-profile-admin-action-btn-text-img-container">
                            <img className="user-profile-admin-action-btn-text-img" src={TrashCan} alt="Ícone de lixeira"/>
                        </div>
                    </div>
                    <div id="user-profile-admin-action-btn-download-account">
                        <p className="user-profile-admin-action-btn-text">
                            BAIXAR DADOS
                        </p>
                        <div className="user-profile-admin-action-btn-text-img-container">
                            <img className="user-profile-admin-action-btn-text-img" src={DownloadArrow} alt="Ícone de download"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}