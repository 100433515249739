import React, { useEffect, useState } from "react"

import "./textInput.css"

export default function TextInput(props: {label?: string, value?: string | number, onValueChange: Function, onSaveBtnClick: Function}){

    const [value, setValue] = useState<string | number>("")
    const [saveBtnClick, setSaveBtnClick] = useState<boolean>(false)

    useEffect (() => {
        setValue(props.value || "" || 0)
    }, [props.value])

    function handleValueChange(value: string | number){
        setValue(value)
        props.onValueChange(value)
    }

    function handleSaveBtnClick(value: boolean){
        setSaveBtnClick(value)
        props.onSaveBtnClick(value)
    }

    return (
        <div id="text-input-full-main">
            <div id="text-input-full-label-container">
                <p id="text-input-full-label">
                    {props.label}
                </p>
            </div>
            <div id="text-input-full-container-a">
                <div id="text-input-full-text">
                    <input id="text-input-full-text-field" onChange={(e) => {handleValueChange(e.target.value)}} value={value}/>
                </div>
                <div id="text-input-full-btn" onClick={() => {handleSaveBtnClick(true)}}>
                    <div id="text-input-full-btn-text">
                        Salvar
                    </div>
                </div>
            </div>
        </div>
    )
}