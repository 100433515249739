import React from "react"
import { Route, BrowserRouter, Routes } from "react-router-dom"

import Main                from "pages/main/main"
import Proceedings         from "pages/proceedings/proceedings"
import FAQ                 from "pages/faq/FAQ"
import ControlPanel        from "pages/controlPanel/controlPanel"
import AdminLogin          from "pages/adminLogin/adminLogin"
import Market              from "pages/market/market"
import Partners            from "pages/partners/partners"
import Raffle              from "pages/raffle/raffle"
import VipRaffle           from "pages/raffle/vipRaffle"
import UserInventory       from "pages/user/inventory/inventory"
import UserProfile         from "pages/user/profile/profile"
import ConfirmEmail        from "pages/confirmEmail/confirmEmail"
import Page404             from "pages/page404/page404"


import AdminProtectedRoute from "routes/adminProtectRoute"
import UserProtectedRoute  from "routes/userProtectedRoute"
import ErrorPage from "pages/errorPage/errorPage"

export default function PageRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AdminProtectedRoute/>}>
                    <Route element={<ControlPanel/> } path="/admin/controle"/>
                </Route>
                <Route element={<UserProtectedRoute/>}>
                    <Route element={<UserProfile/>  } path="/perfil"/>
                    <Route element={<ErrorPage title="503" body="Página em construção."/>} path='/inventario'/>
                    {/* <Route element={<UserInventory/>} path="/inventario"/> */}
                </Route>
                <Route element={<AdminLogin/>   } path="/admin/login"/>
                <Route element={<ConfirmEmail/> } path="/confirmar"/>
                <Route element={<FAQ/>          } path="/faq"/>
                <Route element={<Main/>         } path="/"/>
                <Route element={<Market/>       } path="/mercado"/>
                <Route element={<Partners/>     } path="/parceiros"/>
                <Route element={<Page404/>      } path="*"/>
                <Route element={<Raffle/>       } path="/sorteio"/>
                {/* <Route element={<VipRaffle/>    } path="/sorteio-vip"/> */}
            </Routes>
        </BrowserRouter>
    )
}