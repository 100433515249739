import React, { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"

import "./levelConfiguration.css"

import LevelListElement from "./levelListElement/levelListElement"
import { ILevel }           from "interfaces/ILevel"
import AddIcon          from "assets/icons/add.png"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"

export default function LevelConfiguration(props: { levels: ILevel[], setLevels: Function }){

    const authContext = useContext(AuthContext);
    const [lastPosition, setLastPosition] = useState<number>(0);

    useEffect(() => {
        setLastPosition(
            props.levels.length
        )
    }, [props.levels]);

    function handleDeleteLevel(level: ILevel) {
        APICaller.delete(`/levels/delete`, {
            headers: {
                Authorization: `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            props.setLevels(
                props.levels.filter((l) => l.id !== level.id)
            )
        }).catch((error) => {
            alert(error.response.data.message)
        })
    }

    function handleCreateLevel() {
        APICaller.post('/levels/insert', null, {
            headers: {
                Authorization: `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            const data: ILevel = response.data.data;
            props.setLevels([...props.levels, data]);
        }).catch((error) => {
            alert(error.response.data.message)
        })
    }

    return (
        <div id="level-configuration-main">
            <div id="level-configuration-section-title-container">
                <p id="level-configuration-section-title-text">
                    Níveis
                </p>
            </div>
            <div id="level-configuration-decoration"/>
            <div id="level-configuration-content">
                    <div id="level-configuration-new-level">
                        <div id="level-configuration-new-level-btn" onClick={handleCreateLevel}>
                            <p id="level-configuration-new-level-btn-txt">
                                ADICIONAR NOVO NÍVEL
                            </p>
                            <img 
                                id="level-configuration-new-level-btn-img" 
                                src={AddIcon} 
                                alt="" 
                            />
                        </div>
                    </div>
                    <ul id="level-configuration-level-list">
                        {
                            props.levels.map(level => {
                                return (
                                    <LevelListElement
                                        key={level.id}
                                        id={level.id}
                                        position={level.position}
                                        hasDeleteButton={level.position == lastPosition}
                                        onDeleteLevel={handleDeleteLevel}
                                    />
                                )
                            })
                        }
                    </ul>
            </div>
        </div>
    )
}