import React, { useEffect, useState } from "react"

import "./textInputWithoutSave.css"

export default function TextInputWithoutSave(props: {label?: string, value?: string | number, type?: string, onValueChange: Function, onKeyDown: Function }){

    const [value, setValue] = useState<string | number>("")

    useEffect (() => {
        if (typeof props.value === "string"){
            setValue(props.value || "")
        }
        else if (typeof props.value === "number"){
            setValue(props.value || 0)
        }
    }, [props.value])

    function handleValueChange(value: string){
        setValue(value)
        props.onValueChange(value)
    }

    return (
        <div id="text-input-without-save-main">
            <div id="text-input-without-save-label-container">
                <p id="text-input-without-save-label">
                    {props.label}
                </p>
            </div>
            <div id="text-input-without-save-container">
                <input id="text-input-without-save-text-field" type={props.type} onChange={(e) => {handleValueChange(e.target.value)}} onKeyDown={(e) => props.onKeyDown(e)} value={value}/>
            </div>
        </div>
    )
}