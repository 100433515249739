import { APICaller } from "api/APICaller"
import { AxiosResponse } from "axios"
import React, {useState, useEffect, ReactNode, createContext} from "react"
import logo from "assets/logo/logo-round.png"
import { UserRole } from "enums/Role"

interface IAuthProvider {
    isAuthenticated: boolean
    isAdministrator: boolean
    username: string,
    avatar: string,
    discord: string | undefined,
    trade_url: string | undefined,
    setToken: Function
    getToken: Function
    rmToken: Function
    auditToken: Function
}

export const AuthContext = createContext<IAuthProvider | undefined>(undefined);

export default function AuthProvider(props: {children?: ReactNode}): JSX.Element{
    const [username, setUsername] = useState<string>("");
    const [avatar, setAvatar] = useState<string>("");
    const [discord, setDiscord] = useState<string>();
    const [tradeUrl, setTradeUrl] = useState<string>();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isAdministrator, setIsAdministrator] = useState<boolean>(false)

    useEffect(() => {
        auditToken()
    }, []);

    function setToken(token: string) {
        localStorage.setItem("JWTPayload", token);
        auditToken();
    }

    function getToken(){
        const token = localStorage.getItem("JWTPayload")
        if (token){
            return token
        }
    }

    function rmToken(){
        localStorage.removeItem("JWTPayload")

        if (localStorage.getItem("JWTPayload") === null){
            setIsAuthenticated(false)
            setIsAdministrator(false)
            return true
        } else {
            return false
        }
    }

    function auditToken(){
        const token = localStorage.getItem("JWTPayload")
        if (token === null){
            setIsAuthenticated(false)
            setIsAdministrator(false)
        } else {
            APICaller.get("/auth/specs",
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then((response: AxiosResponse) => {
                if (response.data.role === UserRole.Admin){
                    setIsAdministrator(true)
                    setIsAuthenticated(false)
                } else if (response.data.role === UserRole.Normal) {
                    setIsAdministrator(false)
                    setIsAuthenticated(true)
                    
                    setUsername(response.data.username);
                    setAvatar(logo);
                    setDiscord(response.data.discord);
                    setTradeUrl(response.data.trade_url);
                } else if (response.data.role === UserRole.Steam) {
                    setIsAdministrator(false)
                    setIsAuthenticated(true)
                    
                    setUsername(response.data.username);
                    setAvatar(response.data.avatar);
                    setDiscord(response.data.discord);
                    setTradeUrl(response.data.trade_url);
                } else {
                    setIsAuthenticated(false)
                    setIsAdministrator(false)
                }
            }).catch((error) => {
                setIsAuthenticated(false)
                setIsAdministrator(false)
                alert(error.response.data.message)
            })
        }
    }

    return (
        <AuthContext.Provider value={
            {
                isAuthenticated: isAuthenticated,
                isAdministrator: isAdministrator,
                username: username,
                avatar: avatar,
                discord: discord,
                trade_url: tradeUrl,
                setToken: setToken,
                getToken: getToken,
                rmToken: rmToken,
                auditToken: auditToken
            }
        }>
            {props.children}
        </AuthContext.Provider>
    )
}