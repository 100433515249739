import React, { useContext, useEffect, useState } from "react"

import "./marketControlSold.css"

import MarketControlSoldCard from "./components/marketControlSoldCard/marketControlSoldCard"

import { APICaller } from "api/APICaller"
import { AuthContext } from "context/authProvider"
import { AxiosResponse } from "axios"
import IItem from "interfaces/IItem"
import ICustomer from "interfaces/ICustomer"

export default function MarketControlSold(props: { items: IItem[], setItems: Function, setShouldUpdate: Function }) {
    const [customers, setCustomers] = useState<ICustomer[]>([]);

    const authContext = useContext(AuthContext);

    useEffect(() => {
        const customerIds = props.items.map((d) => d.buyer_id);
        APICaller
            .post('market/customers', 
                customerIds,
                {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`,
                        'Content-Type': 'application/json'
                    }
            }).then((response: AxiosResponse) => {
                const data: ICustomer[] = response.data.data;
                setCustomers(data);
            }).catch((error) => {
                setCustomers([]);
            })
    }, [])

    return (
        <ul id="market-control-sold-main">
            {
                props.items.map(item => {
                    const customer = customers.find((c) => c.id === item.buyer_id);

                    return (
                        <MarketControlSoldCard
                            key={item.id}
                            item={item}
                            customer={customer}
                            setShouldUpdate={props.setShouldUpdate}
                        />
                    )
                })
            }
        </ul>
    )
}