import React, { useEffect, useState } from "react"

import "./buyTicketModal.css"

import CloseCross            from "assets/icons/closeCross.png"
import BuyTicketModalPayment from "./components/buyTicketModalPayment/buyTicketModalPayment"
import BuyTicketModalQR      from "./components/buyTicketModalQR/buyTicketModalQR"

interface IBuyTickerModalController {
    payment: boolean;
    qr:      boolean;
}

export default function BuyTicketModal(props: {onBuyTicketModalExitClick: Function}){
    
    const [controller, setController] = useState<IBuyTickerModalController>({ payment: true, qr: false })
    
    const [ticketQuantity, setTicketQuantity] = useState<number>(1)
    const [paymentMethod, setPaymentMethod] = useState<string>("")

    useEffect(() => {
        setController({ payment: true, qr: false })
        setTicketQuantity(1)
        setPaymentMethod("")
    },[])

    

    function handleTicketQuantityChange(value: any){
        if (/^\d+$/.test(value) === true || value === "") {
            setTicketQuantity(value)
        }
    }

    function handlePaymentSelection(method: string){
        setPaymentMethod(method)
    }

    function handleConfirm(){
        if (paymentMethod === ""){
            console.log("erro")
        } 
        if (ticketQuantity <= 0) {
            console.log("quantidade inválida")
        } else {
            setController({ payment: false, qr: true })
            console.log("gerar qrcode ou redirecionar")
        }
    }

    function handleExitBtnClick(){
        props.onBuyTicketModalExitClick(false)
    }

    return (
        <div id="buy-ticket-modal-main">
            <div id="buy-ticket-modal-content">
                <div id="buy-ticket-modal-exit-btn-container">
                    <img 
                        id="buy-ticket-modal-exit-btn-img" 
                        src={CloseCross} 
                        alt="" 
                        onClick={handleExitBtnClick}
                    />
                </div>
                {
                    controller.payment && 
                    (
                        <BuyTicketModalPayment
                            ticketQuantity={ticketQuantity}
                            onTicketQuantityChange={handleTicketQuantityChange}
                            onPaymentSelection={handlePaymentSelection}
                            onConfirm={handleConfirm}
                        />
                    )
                }
                { 
                    controller.qr && 
                    (
                        <BuyTicketModalQR
                            timer={20}
                        />
                    )
                }
            </div>
        </div>
    )
}