
export enum IRaffleStatus {
    COMING = 1,
    ONGOING = 2,
    COMPLETE = 3,
    DONE = 4
};
export interface IRaffle {
    id: string;
    status: IRaffleStatus;
    item_name: string;
    item_value: number;
    item_float: number;
    item_svc: string;
    item_percentage: number;
    item_image: string;
    boxes_earned: number;
    winner: string;
    level_id: string;
}