import IDate from "./IDate";

export enum PartnerStatus {
    ACTIVE = 1,
    INACTIVE = 2
}

export interface IPartner {
    id: string;
    name: string;
    profession: string;
    description: string;
    external_url: string;
    coupon: string;
    partner_image: string;
    expiration_date_start: string;
    expiration_date_end: string;
    status: PartnerStatus;
}