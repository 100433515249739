import React, { useContext, useEffect, useState } from "react"

import "./pricing.css"

import LogoCaseskins from "assets/logo/logo-round.png"
import LogoSteam     from "assets/icons/steamLogo.png"

import TextInput     from "components/input/textInput/textInput"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"
import { IQuota } from "interfaces/IQuota"
import { AuthContext } from "context/authProvider"

export default function Pricing(props: {}){
    const [steamPercentSelected, setSteamPercentSelected] = useState<boolean>(false)
    const [caseSkinsPercentSelected, setCaseSkinsPercentSelected] = useState<boolean>(false)


    const [steamQuota, setSteamQuota] = useState<IQuota>();
    const [caseSkinsQuota, setCaseSkinsQuota] = useState<IQuota>();
    const [currentQuota, setCurrentQuota] = useState<number>();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        APICaller  
            .get('quota/', {
                headers: {
                    Authorization: `Bearer ${authContext?.getToken()}`
                }
            })
            .then((response: AxiosResponse) => {
                const data: IQuota[] = response.data.data;

                setSteamQuota(data.find((quota) => quota.code === "Steam"));
                setCaseSkinsQuota(data.find((quota) => quota.code === "CaseSkins"));
                
            }).catch((error) => {
                setSteamQuota({
                    'code': '',
                    'id': '',
                    'percentage': 0
                });

                setCaseSkinsQuota({
                    'code': '',
                    'id': '',
                    'percentage': 0
                });

                alert(error.response.data.message)
            });
    }, []);

    function handleCurrentQuotaChange(value: number){
        if (steamPercentSelected) {
            setCurrentQuota(value);
        }
        if (caseSkinsPercentSelected){
            setCurrentQuota(value);
        }
    }

    function handlePercentSave(value: boolean){
        if (steamPercentSelected || caseSkinsPercentSelected) {

            if (steamPercentSelected) {
                APICaller
                    .put(`/quota/${steamQuota?.id}`, {
                        percentage: currentQuota
                    }, {
                        headers: {
                            Authorization: `Bearer ${authContext?.getToken()}`
                        }
                    })
                    .then((response: AxiosResponse) => {
                        const data: IQuota = response.data.data;
                        setSteamQuota(data)
                    }).catch((error) => {
                        alert(error.response.data.message)
                    })
            } else {
                APICaller
                    .put(`/quota/${caseSkinsQuota?.id}`, {
                        percentage: currentQuota
                    }, {
                        headers: {
                            Authorization: `Bearer ${authContext?.getToken()}`
                        }
                    })
                    .then((response: AxiosResponse) => {
                        const data: IQuota = response.data.data;
                        setCaseSkinsQuota(data)
                    }).catch((error) => {
                        alert(error.response.data.message)
                    })
            }
        } else {
            alert('Você deve selecionar uma cotação para alterar.');
        }
    }

    function handlePercentInfoSelected(value: string){
        if (value === "Steam") {
            if (steamPercentSelected) {
                setSteamPercentSelected(false)
            } else {
                setSteamPercentSelected(true)
                setCaseSkinsPercentSelected(false)
                setCurrentQuota(steamQuota?.percentage)
            }
        }
        if (value === "CaseSkins") {
            if (caseSkinsPercentSelected) {
                setCaseSkinsPercentSelected(false)
            } else {
                setSteamPercentSelected(false)
                setCaseSkinsPercentSelected(true)
                setCurrentQuota(caseSkinsQuota?.percentage)
            }
        }
    }

    return (
        <div id="pricing-main">
            <div id="pricing-section-title-container">
                <p id="pricing-section-title-text">
                    Cotação
                </p>
            </div>
            <div id="pricing-decoration"/>
            <div id="pricing-content">
                <div id="percentage-info-container">
                    <div className={steamPercentSelected? "percentage-info-box percentage-info-box-selected":"percentage-info-box"} onClick={() => {handlePercentInfoSelected("Steam")}}>
                        <div className="percentage-info-box-img">
                            <img className="percentage-info-box-img-img" src={LogoSteam}/>
                        </div>
                        <div className="percentage-info-box-value-container">
                            <p className="percentage-info-box-value">
                                {
                                    steamQuota?.percentage ?? 0
                                
                                }%
                            </p>
                        </div>
                    </div>
                    <div className={caseSkinsPercentSelected? "percentage-info-box percentage-info-box-selected":"percentage-info-box"} onClick={() => {handlePercentInfoSelected("CaseSkins")}}>
                        <div className="percentage-info-box-img">
                            <img className="percentage-info-box-img-img" src={LogoCaseskins}/>
                        </div>
                        <div className="percentage-info-box-value-container">
                            <p className="percentage-info-box-value">
                                { 
                                    caseSkinsQuota?.percentage ?? 0
                                }%
                            </p>
                        </div>
                    </div>
                </div>
                <div id="percentage-input-container">
                    <div id="percentage-input">
                        <TextInput label="" onValueChange={handleCurrentQuotaChange} onSaveBtnClick={handlePercentSave} value={currentQuota}/>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}