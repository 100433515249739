import React, {useState} from "react"

import "./vipRaffle.css"

import Group from "assets/icons/group.png"
import Ticket from "assets/icons/goldenTicket.png"

import TextInput from "components/input/textInput/textInput"
import WinnerSection from "components/winnerSection/winnerSection"

import IUser from "interfaces/IUser"
import IVipRaffle from "interfaces/IVipRaffle"

export default function VipRaffle(props: {}){

    const [raffle, setRaffle] = useState<IVipRaffle>({
        id: '',
        number: 0,
        percentage: 0,
        tickets_earned: 0,
        captation_balance: 0,
        participant_amount: 0,
        captation: 0,
        status: 'string',
        date: '1/1/1999',
        time: '00:00',
        countdown: {
            days:    0,
            hours:   0,
            minutes: 0,
            seconds: 0 
        },
        winner: undefined
    })

    function handleRaffleDateChange(value: string){

    }

    function handleRaffleTimeChange(value: string){

    }

    function handleRaffleDateSave(value: boolean){

    }

    function handleRaffleTimeSave(value: boolean){

    }


    function parseStatusColor(status: number){
        if (status === 1){
            return "#CBCBCB"
        } 
        if (status === 2){
            return "#FF784E"
        }
        if (status === 3){
            return "#5CB85C"
        }
    }

    function parseStatus(status: number){
        if (status === 1){
            return "EM BREVE"
        } 
        if (status === 2){
            return "EM CAPTAÇÃO"
        }
        if (status === 3){
            return "CAPTAÇÃO COMPLETA"
        }
    }


    return (
        <div id="vip-raffle-main">
            <div id="vip-raffle-section-title-container">
                <p id="vip-raffle-section-title-text">
                    Sorteio VIP
                </p>
            </div>
            <div id="vip-raffle-decoration"/>
            <div id="vip-raffle-content-container">
                <div id="vip-raffle-content-captation">
                    <div id="vip-raffle-content-captation-title">
                        <p id="vip-raffle-content-captation-title-txt">
                            Captação
                        </p>
                    </div>
                    <div className="vip-raffle-content-captation-resources">
                        <div className="vip-raffle-content-captation-resources-img-container">
                            <img 
                                className="vip-raffle-content-captation-resources-img" 
                                src={Group} 
                                alt=""
                                draggable={false} 
                            />
                        </div>
                        <div className="vip-raffle-content-captation-resources-data-container">
                            <p className="vip-raffle-content-captation-resources-data-txt">
                                {raffle.participant_amount}
                            </p>
                        </div>
                    </div>
                    <div className="vip-raffle-content-captation-resources">
                        <div className="vip-raffle-content-captation-resources-img-container">
                            <img 
                                className="vip-raffle-content-captation-resources-img" 
                                src={Ticket} 
                                alt="" 
                                draggable={false} 
                            />
                        </div>
                        <div className="vip-raffle-content-captation-resources-data-container">
                            <p className="vip-raffle-content-captation-resources-data-txt">
                                {raffle.tickets_earned}
                            </p>
                        </div>
                    </div>
                    <div className="vip-raffle-content-captation-resources">
                        <div className="vip-raffle-content-captation-resources-img-container">
                            <p id="vip-raffle-content-captation-resources-currency-txt">
                                R$
                            </p>
                        </div>
                        <div className="vip-raffle-content-captation-resources-data-container">
                            <p id="vip-raffle-content-captation-resources-value-txt">
                                {raffle.captation_balance}
                            </p>
                        </div>  
                    </div>
                </div>
                <div id="vip-raffle-content-status-progress">
                    <div id="vip-raffle-content-status-progress-status">
                        <div id="vip-raffle-content-status-progress-title">
                            <p id="vip-raffle-content-status-progress-title-txt">
                                Status
                            </p>
                        </div>
                        <div id="vip-raffle-content-status-progress-container">
                            <div id="vip-raffle-content-status-progress-box" style={{backgroundColor: parseStatusColor(2)}}>
                                <p id="vip-raffle-content-status-progress-box-txt">
                                    {
                                        parseStatus(2)
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="vip-raffle-content-status-progress-bar">
                        <div id="vip-raffle-content-status-progress-bar-percentage">
                            <p id="vip-raffle-content-status-progress-bar-percentage-txt">
                                {raffle.percentage}%
                            </p>
                        </div>
                        <div id="vip-raffle-content-status-progress-bar-container">
                            <div id="vip-raffle-content-status-progress-bar-fill" style={{width: (`${raffle.percentage}%`)}}>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="vip-raffle-content-inputs">
                    <div className="vip-raffle-content-inputs-container">
                        <TextInput
                            label="Definir data"                        
                            onValueChange={handleRaffleDateChange}
                            onSaveBtnClick={handleRaffleDateSave}
                            value={raffle.date}
                        />
                    </div>
                    <div className="vip-raffle-content-inputs-container">
                        <TextInput
                            label="Definir hora"
                            onValueChange={handleRaffleTimeChange}
                            onSaveBtnClick={handleRaffleTimeSave}
                            value={raffle.time}
                        />
                    </div>
                </div>
                <div id="vip-raffle-content-info">
                    <div id="vip-raffle-content-info-title">
                        <p id="vip-raffle-content-info-title-txt">
                            Sorteador
                        </p>
                    </div>
                    <div id="vip-raffle-content-info-decoration"/>
                    <div id="vip-raffle-content-info-main-info">
                        <p id="vip-raffle-content-info-main-info-txt">
                            EXISTEM 
                        </p>
                        <p id="vip-raffle-content-info-main-info-value-txt">{raffle?.participant_amount}</p>
                        <p id="vip-raffle-content-info-main-info-txt">
                            JOGADORES NESTE SORTEIO
                        </p>
                    </div>
                </div>
                {
                raffle.winner !== undefined &&
                    (
                        <div id="vip-raffle-content-winner">
                            <WinnerSection 
                                winnerData={raffle.winner}
                                dateData={raffle.date}
                                timeData={raffle.time}
                            />
                        </div>
                    )
                }
                <div id="vip-raffle-content-action-btns">
                    <div id="vip-raffle-content-action-btns-start">
                        <p className="vip-raffle-content-action-btns-txt">
                            INICIAR PROCESSO
                        </p>
                    </div>
                    <div id="vip-raffle-content-action-btns-reset">
                        <p className="vip-raffle-content-action-btns-txt">
                            RESETAR SORTEIO
                        </p>
                    </div>
                    <div id="vip-raffle-content-action-btns-download-data">
                        <p className="vip-raffle-content-action-btns-txt">
                            BAIXAR DADOS
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}