import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import "./header.css"

import Logo from "assets/logo/logo-with-name.png"

import AuthButton     from "./components/authButton/authButton"
import AdminConnected from "./components/adminConnected/adminConnected"
import UserConnected  from "./components/userConnected/userConnected"
import { AuthContext } from "context/authProvider"


export default function Header(props: {renderAuthScreen?: Function, renderBuyTicketScreen?: Function}) {

    const authContext = useContext(AuthContext)

    useEffect(() => {
        
    }, []);

    function handleAuthBtnClick() {
        if (props.renderAuthScreen !== undefined){
            props.renderAuthScreen(true)
        }
    }

    function handleBuyTicketBtnClick() {
        if (props.renderBuyTicketScreen !== undefined){
            props.renderBuyTicketScreen(true)
        }
    }

    return (
        <div id="header-main">
            <div id="header-logo-container">
                <Link id="header-logo-link" to={"/"} draggable={false}>
                    <img id="header-logo" src={Logo} draggable={false} alt="" />
                </Link>
            </div>
            <div id="header-links-container">
                <div id="header-links">
                    <Link className="header-link" to="/" >
                        <p className="header-link-text" id="x">Página Inicial</p>
                    </Link>
                    <Link className="header-link" to="/faq">
                        <p className="header-link-text" id="x">FAQ</p>
                    </Link>
                    <Link className="header-link" to="/parceiros">
                        <p className="header-link-text" id="x">Parceiros</p>
                    </Link>
                    {/* <Link className="header-link" to="/">
                        <p className="header-link-text" id="x">Termos e diretrizes</p>
                    </Link> */}
                    {/* <Link className="header-link" to="/">
                        <p className="header-link-text" id="x">Suporte</p>
                    </Link> */}
                    {/* <Link className="header-link" to="/">
                        <p className="header-link-text" id="x">Discord</p>
                    </Link> */}
                    {/* <Link className="header-link" to="/sorteio-vip">
                        <p className="header-link-text" id="x">VIP</p>
                    </Link> */}
                    <Link className="header-link" to="/mercado">
                        <p className="header-link-text" id="x">Mercado</p>
                    </Link>
                    <Link className="header-link" to="/sorteio">
                        <p className="header-link-text" id="x">Sorteios</p>
                    </Link>
                </div>
            </div>
            <div id="header-user-info-container">
                {
                    authContext?.isAdministrator &&
                    (
                        <AdminConnected/>
                    )
                }
                {
                    authContext?.isAuthenticated &&
                    (
                        <UserConnected renderBuyTicketScreen={handleBuyTicketBtnClick}/>
                    )
                }
                {
                    !authContext?.isAuthenticated && !authContext?.isAdministrator &&
                    (
                        <AuthButton onAuthClick={handleAuthBtnClick}/>
                    )
                }
                
                
            </div>
        </div>
    )
}