import React, { useState, useContext } from "react"

import "./userLoginModal.css"

import Logo                     from "assets/icons/logoWithText.png"
import SteamLogo                from "assets/icons/steamLogo.png"
import CloseCross               from "assets/icons/closeCross.png"
import TextInputWithoutSave     from "../../input/textInputWithoutSave/textInputWithoutSave"
import PasswordInputWithoutSave from "../../input/passwordInput/passwordInput"
import { APICaller } from "api/APICaller"
import { AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"


export default function UserLoginModal(
    props: {
        onAuthModalExitClick: Function, 
        onRegisterModalClick: Function,
        onSteamLoginModalClick: Function
    }
){

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const authContext = useContext(AuthContext);

    function handleExitBtnClick(){
        props.onAuthModalExitClick(false)
    }

    function handleUsername(value: string){
        setUsername(value);
    }
    
    function handlePasswordChange(value: string){
        setPassword(value);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            handleLoginBtnClick();
        }
    }

    function handleLoginBtnClick(){
        APICaller
            .post('/auth/login', {
                username: username,
                password: password,
            })
            .then((response: AxiosResponse) => {
                const data = response.data.data;
                authContext?.setToken(data);
                props.onAuthModalExitClick(false);
            }).catch((error) => {
                alert(error.response.data.message);
            })
    }

    function handleRegisterBtnClick(){
        props.onRegisterModalClick(true)
    }

    function handleForgotPasswordBtnClick(){
        console.log("forgot password")
    }

    function handleSteamLoginBtnClick(){
        props.onSteamLoginModalClick(true)
    }

    return (
        <div id="user-login-modal-main">
            <div id="user-login-modal-content">
                <div id="user-login-modal-exit-btn-container">
                    <div id="user-login-modal-exit-btn">
                        <img id="user-login-modal-exit-btn-img" src={CloseCross} onClick={handleExitBtnClick} draggable="false"/>
                    </div>
                </div>
                <div id="user-login-modal-logo-container">
                    <img id="user-login-modal-logo" src={Logo} draggable="false"/>
                </div>
                <div id="user-login-modal-input-container">
                    <div className="user-login-modal-input">
                        <TextInputWithoutSave label="Login" onValueChange={handleUsername} onKeyDown={handleKeyDown}/>
                    </div>
                    <div className="user-login-modal-input">
                        <PasswordInputWithoutSave label="Senha" onValueChange={handlePasswordChange} onKeyDown={handleKeyDown} value={password}/>
                    </div>
                </div>
                <div id="user-login-modal-btn-container">
                    <div id="user-login-modal-btn2" onClick={handleLoginBtnClick}>
                        <p id="user-login-modal-btn-text">
                            Entrar
                        </p>
                    </div>
                    <div id="user-register-btn2" onClick={handleRegisterBtnClick}>
                        <p id="user-register-btn-text">
                            Cadastre-se
                        </p>
                    </div>
                </div>
                <div id="user-auxiliar-btn-container">
                    <p id="user-auxiliar-btn-recovery-password" onClick={handleForgotPasswordBtnClick}>
                        Esqueci minha senha
                    </p>
                    <div id="user-auxiliar-btn-login-steam-container" onClick={handleSteamLoginBtnClick}>
                        <img id="user-auxiliar-btn-login-steam-img" src={SteamLogo} alt="" draggable={false}/>
                        <p id="user-auxiliar-btn-login-steam-text">
                            Iniciar sessão
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}