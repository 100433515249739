import React, { useEffect, useState } from "react"

import "./buyTicketModalQR.css"

import Code from "./code.png"

export default function BuyTicketModalQR(props: {timer: number}){

    const [elapsedTime, setElapsedTime] = useState<number>(0)

    useEffect(() => {
        
    },[])

    function imprime(){
        console.log("rodou")
        setElapsedTime(elapsedTime + 1000)
    }

    return (
        <div id="buy-ticket-modal-qr-container">
            <div id="buy-ticket-modal-qr-info">
                <p id="buy-ticket-modal-qr-info-txt">
                    Para continuar, realize o pagamento do QR Code abaixo:
                </p>
            </div>
            <div id="buy-ticket-modal-qr">
                <div id="buy-ticket-modal-qr-img-container">
                    <img
                        id="buy-ticket-modal-qr-img"
                        src={Code} 
                        alt=""
                        draggable={false}
                    />
                </div>
            </div>
            <div id="buy-ticket-modal-qr-value">
                <p id="buy-ticket-modal-qr-value-txt">
                    R$ 2000,00
                </p>
            </div>
            <div id="buy-ticket-modal-qr-timer">
                <p id="buy-ticket-modal-qr-timer-txt">
                    Este QR Code expira em 10 minutos e 30 segundos
                </p>
            </div>
        </div>
    )
}