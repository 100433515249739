import React, {useState, useEffect} from "react"

import "./dropdown.css"

export default function Dropdown(props: {}){

    const [width, setWidth] = useState<number>(0)

    const [renderDropDown, setRenderDropDown] = useState<boolean>(false)

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [position, setPosition] = useState<any>({positionX: 0, positionY: 0})

    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        setRenderDropDown(false)
    }, [windowDimensions]);
    
    useEffect(() => {
        const elemento = document.getElementById("market-add-item-dropdown-main");
        if (elemento != null) {
            const rect = elemento.getBoundingClientRect();
            setWidth(rect.width)
            setPosition({
                X: rect.left,
                Y: rect.top + rect.height
            })
        }
        
    }, [window.innerWidth,window.innerHeight])
    
    function handleRenderDropdown(){
        if (renderDropDown){
            setRenderDropDown(false)
        } else {
            setRenderDropDown(true)
        }
    }

    function dropdownBtnStyleController(){
        if (renderDropDown){
            return {
                borderBottomLeftRadius: "0px", 
                borderBottomRightRadius: "0px",
                // borderRight: "3px #162D46 solid",
                // borderLeft: "3px #162D46 solid",
                // borderTop: "3px #162D46 solid"
            }
        } else {
            return {
                borderBottomLeftRadius: "26px", 
                borderBottomRightRadius: "26px",
            }
        }
    }

    return (
        <>
            <div id="market-add-item-dropdown-main">
                <div id="market-add-item-dropdown-label-container">
                    <p id="market-add-item-dropdown-label">
                        Categoria
                    </p>
                </div>
                <div id="market-add-item-dropdown-container"
                    onClick={handleRenderDropdown} 
                    style={dropdownBtnStyleController()}
                >
                </div>
            </div>
            <ul id="market-add-item-dropdown-list" 
                style={{display: renderDropDown? "flex": "none", top: position.Y, left: position.X, width: width}}
            >
            </ul>
        </>
    )
}